import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown'
import { WebUtils } from "../scripts/utils";
import styles from "./markdown.module.css";
import services from "../scripts/services";
import {
    FacebookShareButton,
    FacebookIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon
} from "next-share";

const DisplayArticle = ({ user, strings, isMobile, userLang }) => {
    const [currentArticle, setCurrentArticle] = useState(null);
    const navigate = useNavigate();

    const { slug } = useParams();
    let _filename = slug.replace(/-/g, "_");
    let iconSize = (isMobile) ? 20 : 32;

    useEffect(() => {
        // Set the command string to get this article
        // let _command_str = '{"action": "get-article", "file": "' + _filename + '"}';
        let _command = {
            "action": "get-article",
            "file": `${_filename}_${userLang}`
        }
        let _command_str = JSON.stringify(_command);

        // Call the articles service
        WebUtils.request(services["webServices"], _command_str).then((_data) => {
        // Fetched data may have carriage return and newline characters
        // _data = StringUtils.cleanCRNL(_data);
        let _dataJSON = JSON.parse(_data);

        setCurrentArticle(_dataJSON);
        });
    }, [_filename, userLang]);

    // if (null === user) {
    //     return(
    //         <div className = "container">
    //             <div className = "row justify-content-center mt-3">
    //                 <div className = "col-md-4 text-center">
    //                     <p className = "lead">{ StringUtils.capitalizeSentences(strings.modalContent.displayArticles.login) }</p>
    //                     <button type = "button" className = "btn btn-outline-dark mt-3" onClick = { (e) => {navigate(process.env.REACT_APP_PATH)} }>{ StringUtils.capitalizeWords(strings.modalContent.buttons.home) }</button>
    //                 </div>
    //             </div>
    //         </div>
    //     )        
    // }
    // else if ((null !== user) && ( user.emailVerified ) && ( null !== currentArticle )){ 
    //     const articleURL = process.env.REACT_APP_ABSOLUTE_PATH + "/?mode=articles&slug=" + currentArticle.slug;
    //     return(
    //         <div className = { styles.markdownContainer }>
    //             <Helmet>
    //                 <meta charSet = "utf-8" />
    //                 <title>{ `${currentArticle.title} | ${strings.seo.companySuffix}` }</title>
    //                 <meta name = "description" content = { currentArticle.desc }/>
    //                 <link rel = "canonical" href = { articleURL } />
    //                 <meta name = "og:title" content = { currentArticle.title } />
    //                 <meta name = "og:description" content = { currentArticle.desc } />
    //                 <meta name = "og:image" content = { `https://${currentArticle.img}` } />
    //                 <meta name = "og:url" content = { articleURL } />
    //                 <meta name = "og:type" content = "article" />
    //                 <meta name = "google-adsense-account" content = "ca-pub-6615401430059807"></meta>
    //             </Helmet>
    //             <div className = "container-fluid">
    //                 <div className = "row align-items-center">
    //                     <div className = { [ styles.socialButtons, "col"].join(" ") }>
    //                         <FacebookShareButton
    //                             url = { articleURL }
    //                             quote = { "Facebook" }
    //                             hashtag = "#Inveyo">
    //                             <FacebookIcon size = { iconSize } round />
    //                         </FacebookShareButton>
    //                         <RedditShareButton
    //                             url = { articleURL }
    //                             title = { currentArticle.title }>
    //                             <RedditIcon size = { iconSize } round />
    //                         </RedditShareButton>
    //                         <TelegramShareButton
    //                             url = { articleURL }
    //                             title = { currentArticle.title }
    //                             hashtag = "#Inveyo">
    //                             <TelegramIcon size = { iconSize } round />
    //                         </TelegramShareButton>
    //                         <TwitterShareButton
    //                             url = { articleURL }
    //                             title = { currentArticle.title }                                
    //                             hashtag = "Inveyo">
    //                             <TwitterIcon size = { iconSize } round />
    //                         </TwitterShareButton>
    //                         <LinkedinShareButton
    //                             url = { articleURL }
    //                             title = { currentArticle.title }>
    //                             <LinkedinIcon size = { iconSize } round />
    //                         </LinkedinShareButton>
    //                         <EmailShareButton
    //                             url = { articleURL }
    //                             title = { currentArticle.title }
    //                             hashtag = "Inveyo">
    //                             <EmailIcon size = { iconSize } round />
    //                         </EmailShareButton>
    //                     </div>
    //                     <div className = "col">
    //                         <div className = "text-end">
    //                             <button type = "button" className = "d-inline-block btn-close" aria-label = "close" onClick = { (e) => {navigate(process.env.REACT_APP_PATH)} }></button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className = "mb-3">
    //                 <h1>{ currentArticle.title }</h1>
    //                 <h6>Author: <span className = { styles.author }>{ currentArticle.author }</span></h6>
    //                 <h6>Publish date: <span className = { styles.date }>{ currentArticle.date.substring(0, currentArticle.date.indexOf("T")) }</span></h6>
    //             </div>
    //             <div>
    //                 { <ReactMarkdown>{ currentArticle.body }</ReactMarkdown> }
    //             </div>
    //         </div>
    //     )
    // } else {
    //     return(
    //         <div className = "container">
    //             <div className = "row justify-content-center mt-3">
    //                 <div className = "col-md-4 text-center">
    //                     <p className = "lead">{ StringUtils.capitalizeSentences(strings.modalContent.displayArticles.verifyEmail) }</p>
    //                     <button type = "button" className = "btn btn-outline-dark mt-3" onClick = {(e) => {navigate(process.env.REACT_APP_PATH)}}>{ StringUtils.capitalizeWords(strings.modalContent.buttons.home) }</button>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    if (null !== currentArticle) {
        const articleURL = process.env.REACT_APP_ABSOLUTE_PATH + "/?mode=articles&lang=" + userLang + "&slug=" + currentArticle.slug;
        return(
            <div className = { styles.markdownContainer }>
                <Helmet>
                    <meta charSet = "utf-8" />
                    <title>{ `${currentArticle.title} | ${strings.seo.companySuffix}` }</title>
                    <meta name = "description" content = { currentArticle.desc }/>
                    <link rel = "canonical" href = { articleURL } />
                    <link rel = "alternate" hreflang = { userLang } href = { articleURL } />
                    <meta name = "og:title" content = { currentArticle.title } />
                    <meta name = "og:description" content = { currentArticle.desc } />
                    <meta name = "og:image" content = { `https://${currentArticle.img}` } />
                    <meta name = "og:url" content = { articleURL } />
                    <meta name = "og:type" content = "article" />
                    <meta name = "google-adsense-account" content = "ca-pub-6615401430059807"></meta>
                </Helmet>
                <div className = "container-fluid">
                    <div className = "row align-items-center">
                        <div className = { [ styles.socialButtons, "col"].join(" ") }>
                            <FacebookShareButton
                                url = { articleURL }
                                quote = { "Facebook" }
                                hashtag = "#Inveyo">
                                <FacebookIcon size = { iconSize } round />
                            </FacebookShareButton>
                            <RedditShareButton
                                url = { articleURL }
                                title = { currentArticle.title }>
                                <RedditIcon size = { iconSize } round />
                            </RedditShareButton>
                            <TelegramShareButton
                                url = { articleURL }
                                title = { currentArticle.title }
                                hashtag = "#Inveyo">
                                <TelegramIcon size = { iconSize } round />
                            </TelegramShareButton>
                            <TwitterShareButton
                                url = { articleURL }
                                title = { currentArticle.title }                                
                                hashtag = "Inveyo">
                                <TwitterIcon size = { iconSize } round />
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url = { articleURL }
                                title = { currentArticle.title }>
                                <LinkedinIcon size = { iconSize } round />
                            </LinkedinShareButton>
                            <EmailShareButton
                                url = { articleURL }
                                title = { currentArticle.title }
                                hashtag = "Inveyo">
                                <EmailIcon size = { iconSize } round />
                            </EmailShareButton>
                        </div>
                        <div className = "col">
                            <div className = "text-end">
                                <button type = "button" className = "d-inline-block btn-close" aria-label = "close" onClick = { (e) => {navigate(process.env.REACT_APP_PATH)} }></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = "mb-3">
                    <h1>{ currentArticle.title }</h1>
                    <h6 className = "text-capitalize">{ strings.articleTitles.author }:&nbsp;<span className = { styles.author }>{ currentArticle.author }</span></h6>
                    <h6 className = "text-capitalize">{ strings.articleTitles.publishDate }:&nbsp;<span className = { styles.date }>{ currentArticle.date.substring(0, currentArticle.date.indexOf("T")) }</span></h6>
                </div>
                <div>
                    { <ReactMarkdown>{ currentArticle.body }</ReactMarkdown> }
                </div>
            </div>
        )        
    } else {
        return(
            <div className = "container">
                <div className = "row justify-content-center mt-3">
                    <div className = "col-md-4 text-center">
                        <p className = "lead">{ strings.modalContent.displayArticles.verifyEmail }</p>
                        <button type = "button" className = "btn btn-outline-dark mt-3" onClick = {(e) => {navigate(process.env.REACT_APP_PATH)}}>{ strings.modalContent.buttons.home }</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default DisplayArticle