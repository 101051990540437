import React, { useContext, useEffect } from "react";
import { UserContext } from "./user-context";
import EntityTable from "./table-entity";
import styles from "./table.module.css";
import { WebUtils } from "../scripts/utils";
import services from "../scripts/services";

const isDictionaryEmpty = (data) => {
    let _emptyFlag = false;
    let _key;

    for (_key in data) {
        let _report = data[_key];
        if (Object.keys(_report).length === 0) {
            _emptyFlag = true;
        }
    }

    return _emptyFlag
}

const Entities = ({ isAdvancedSearchVisible, searchParameters, strings, isMobile }) => {
    const [entitiesDoc, setEntitiesDoc] = React.useState(null);
    let _entity = searchParameters["entity"];

    // Get context
    const userContext = useContext(UserContext);
    const user = userContext.FBUser;
    const isProUser = userContext.isPro;
    const justUpgraded = userContext.justUpgraded;
    const justVerified = userContext.justVerified;  

    useEffect(() => {
        if(null !== _entity){
            // Get the user access token
            let _token;
            if (null !== user) {
                _token = user.accessToken
            } else {
                _token = "none"
            }

            // Get the user's email verification status
            let _isVerified;
            try {
                _isVerified = user.emailVerified;
            } catch {
                _isVerified = false;
            }          

            // Set the command string to get entity data
            let _command = {
                "action": "get-entity-docs",
                "entity": _entity,
                "token": _token,
                "is-verified": _isVerified,
                "is-pro-user": isProUser,
                "just-verified": justVerified,
                "just-upgraded": justUpgraded
            }
            let _command_str = JSON.stringify(_command);

            WebUtils.request(services["webServices"], _command_str).then((_data) => {
                if (null !== _data ) { // This will occur if request fails
                    // _data = StringUtils.cleanCRNL(_data);

                    if ("" === _data) {
                        setEntitiesDoc(false);
                    } else {
                        _data = JSON.parse(_data);
                        let _isEmpty = isDictionaryEmpty(_data);
                        if (_isEmpty) {
                            setEntitiesDoc(false);  
                        } else {
                            setEntitiesDoc(_data); 
                        }
                    }
                }
            });
        };
        return () => {
            setEntitiesDoc(null);
        };
    }, [_entity, user, isProUser, justVerified, justUpgraded]);

    if (null === _entity) {
        return(
            <div className = { styles.loadingMessage }>{ strings.entityMessages.waiting["label"] }</div>
        )        
    } else {
        if (null === entitiesDoc) {
            return(
                <div className = { styles.loadingMessage }>{ strings.entityMessages.loading["label"].replace("$", _entity.toUpperCase()) }</div>
            )            
        } else if (false === entitiesDoc) {
            return(
                <div className={ styles.loadingMessage }>{ strings.entityMessages.noData["label"].replace("$", _entity.toUpperCase()) }</div>
            )            
        } else {
            return(
                <React.Fragment>
                    {
                        Object.keys(entitiesDoc).map((CIK) => <EntityTable key = { CIK } CIK = { CIK } data = { entitiesDoc[CIK] } isAdvancedSearchVisible = { isAdvancedSearchVisible } searchParameters = { searchParameters } strings = { strings } isMobile = { isMobile }></EntityTable>)
                    }
                </React.Fragment>
            )            
        }
    }
}

export default Entities