import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "./user-context.jsx";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvancedSearchArea from "./advanced-search-area";
import styles from "./search-area.module.css";
import "react-calendar/dist/Calendar.css";
import { DateUtils, StringUtils } from "../scripts/utils";

const IDS = {
    "area": { "id": "searchArea" },
    "group": { "id": "searchGroup" },
    "input": { "id": "searchInput" }
}

const SearchArea = ({ USER_PARAMS, REGEX, SEARCH_INPUT_REGEX, DEFAULT_SEARCH_PARAMETERS, isAdvancedSearchVisible, setIsAdvancedSearchVisible, searchValue, setSearchValue, searchParameters, setSearchParameters, youngestReportPeriod, allCIKs, allTickers, allReportPeriods, allArticles, allIndustries, strings, setNotification }) => {
    // Get context
    const userContext = useContext(UserContext);
    const user = userContext.FBUser;
    const isProUser = userContext.isPro;
    const justUpgraded = userContext.justUpgraded;
    const justVerified = userContext.justVerified;
  
    const autoCompleteItems = useRef(null);
    const [autoCompleteItemsVisible, setAutoCompleteItemsVisible] = useState(0);
    const [calendarVisible, setCalendarVisible] = useState(0);
    const navigate = useNavigate();
    const searchStrings = strings.search;

    const parseUserInputAdvanced = (userInput) => {
      /**
       * The `parseUserInput` method accepts an `userInput` string and parses it to determine the
       * intent of the user.
       * 
       * @param {string} userInput
       * @return {array} [_intent, _sanitized_input]
       * @catch {array} [null, null] Returns [null, null] on error
       */    
      try {
        const extractDateComponents = (yearFirst, input) => {
          let _year, _month, _day;

          // Remove any alphabetic characters from input
          input = input.replace(/[a-z]/g, "");
          
          if (yearFirst) {
            _year = input.slice(0, 4);
            _month = input.slice(5, 7);
            _day = input.slice(8, 10);
          } else {
            _month = input.slice(0, 2);
            _day = input.slice(3, 5);
            _year = input.slice(6, 10);
          }
          
          return [_year, _month, _day];
        }

        let _intent;
        let _regex;
        let _match;
        let _sanitizedInput;
        let _userInput;

        // Sanitize the user input:
        // 1. Replace any non-alphanumeric+ characters (alphanumeric + `-`, `_`, ` `)
        // 2. Replace any multiple whitespace characters with single whitespace character
        // 3. Lowercase the input
        // 4. Remove any whitespace        
        _userInput = userInput.replace(REGEX["NOTALPHANUM+"], "").replace(REGEX["MULTIPLE_SPACE"], " ").toLowerCase().trim();
        
        // Iterate over regular expressions to determine intent
        for (_regex in SEARCH_INPUT_REGEX) {
          if (SEARCH_INPUT_REGEX[_regex].test(_userInput)) {
            _match = _regex;
            switch (_regex) {
              case ("DATE1"):
                _intent = "report";
                break;
              case ("DATE2"):
                _intent = "report";
                break;
              case ("REPORT1"):
                _intent = "report";
                break;
              case ("REPORT2"):
                _intent = "report";
                break;
              case ("TICKER"):
                _intent = "entity";
                break;
              case ("FIRMNAME"):
                _intent = "entity";
                break;
              case("CIK"):
                _intent = "entity";
                break;
              default:
                _intent = null;
                break;
            }
          }
        }

        if ("report" === _intent) {
          let _year, _month, _day;
          [_year, _month, _day] = (("DATE1" === _match) || ("REPORT1" === _match)) ? extractDateComponents(false, _userInput) : extractDateComponents(true, _userInput);
          _sanitizedInput = _year + "_" + _month + "_" + _day;
        } else {
          _sanitizedInput = _userInput;
        }

        return [_intent, _sanitizedInput, _match]
      } catch {
        return [null, null]
      }
    }

    const checkIfEntityExists = (entity) => {
      /**
       * The `checkIfEntityExists` method accepts a string value and checks if the value exists in
       * `allCIKs` or `allTickers`.
       * 
       * @param {string} entity Lowercase value of entity submission.
       * @return {boolean} _ `true` if entity exists; `false` otherwise.
       * @catch {Error} err
       */
      try {
        // Note: `entity` parameter is a sanitized input value that is determined to be an entity
        // submission. Sanitized input values are already lowercased.

        if (SEARCH_INPUT_REGEX["TICKER"].test(entity)) {
          for (let _ticker of allTickers.current) {
            if (entity === _ticker[0].toLowerCase()) { // `entity` should already be lowercase; lowercasing here just to make sure
              return true
            }
          }
          return false
        }

        if (SEARCH_INPUT_REGEX["CIK"].test(entity)) {
          for (let _cik of allCIKs.current) {
            if (entity === _cik[0]) {
              return true
            }
          }
          return false
        }

        if (SEARCH_INPUT_REGEX["FIRMNAME"].test(entity)) {
          // Note: The `allCIKs.current` and `allTickers.current` arrays of arrays each contain firm names.
          // Either can be used with this logic. The `allTickers.current` array is chosen so that the ticker
          // of the firm can be returned.
          for (let _ticker of allTickers.current) {
            // Note: The logic to sanitize `_ticker[1]` mimics the sanitization logic of the 
            // `parseUserInputAdvanced` method.
            if (entity === _ticker[1].replace(REGEX["NOTALPHANUM+"], "").replace(REGEX["MULTIPLE_SPACE"], " ").toLowerCase().trim()) {
              // Note: For firmnames, return the ticker of the firm
              return [true, _ticker[0]]
            }
          }
          return false
        }
      } catch (err) {
        console.error(err);
      }
    }

    const checkIfReportExists = (report) => {
      /**
       * The `checkIfReportExists` method accepts a string value and checks if the value exists in
       * `allReportPeriods`.
       * 
       * @param {string} report
       * @return {boolean} _ `true` if the report value exists; `false` otherwise.
       * @catch {Error} err
       */
      try {
        for (let _reportPeriod of allReportPeriods.current) {
          if (report === _reportPeriod) {
            return true
          }
        }
        return false
      } catch (err) {
        console.error(err);
      }
    }

    const prettifyReportName = (sanitizedInput) => {
      /**
       * The `prettifyReportName` method accepts an input string for a particular report and "prettifies"
       * the name.
       * 
       * @param {string} sanitizedInput The raw input string.
       * @return {string} template The "prettified" report name.
       * @catch {Error} err
       */
      try {
        let [year, month, day] = sanitizedInput.split("_");
        let prettyReportName = StringUtils.capitalizeWords(strings.search.prettyReportName.prefix) + " " + month + "-" + day + "-" + year;
        return prettyReportName
      } catch (err) {
        console.error(err);
      }
    }

    const resetSearchInput = () => {
      /**
       * The `resetSearchInput` method resets the search input value to the default search string.
       * 
       * @param undefined
       * @return undefined
       * @catch {Error} err
       */
      try {
        setSearchValue(strings.search.input.label);
        setAutoCompleteItemsVisible(0);
      } catch (err) {
        console.error(err);
      }
    }

    const processSearchByAuth = (intent, sanitizedInput, searchParameters) => {
      /**
       * The `processSearchByAuth` method processes a search input. It checks the search against the 
       * authorizations for the type of user (guest, basic, pro). It will reject the search if the user
       * does not have sufficient permissions. It will also set notifications based on the current user
       * type, e.g. suggestion notification to upgrade.
       * 
       * @param {string} intent `entity` or `report`
       * @param {string} sanitizedInput The sanitized search input
       * @param {object} searchParameters Search parameters object
       * @return {array} [_searchParametersCopy, _newSearchFieldValue, _notification]
       * @catch {Error} err
       */

      // DEBUG
      // console.group();
      //   console.log(`intent: ${intent}`);
      //   console.log(`sanitizedInput: ${sanitizedInput}`);
      //   console.log(`searchParameters: ${searchParameters}`);
      // console.groupEnd();
      // DEBUG

      const _searchParametersCopy = { ...searchParameters };

      let userType;
      try {
        if (null === user) {
          userType = "guest";
        } else {
          if (user.emailVerified || justVerified) {
            userType = "basic";
          }

          if (isProUser || justUpgraded) {
            userType = "pro"
          }
        }
      } catch {
        userType = "guest";
      }

      // DEBUG
      // console.log(`userType is ${userType}`);
      // DEBUG

      let _notification = null;
      let _newSearchFieldValue = null;

      if ("entity" === intent) {
        switch(userType) {
          case("guest"):
            // Guest users can only see limited entities data
            _notification = {
              "category": "account",
              "notice": "upgradeRequestNoAccountLimitedData",
              "options": [{ "option": sanitizedInput, "textStyle": "uppercase" }]
            };
            break;
          case("basic"):
            // Basic users can only see limited entities data
            _notification = {
              "category": "account",
              "notice": "upgradeRequestBasicAccountLimitedData",
              "options": [{ "option": sanitizedInput, "textStyle": "uppercase" }]
            }
            break;
          default:
            // pass
            break;
        }
        _searchParametersCopy["intent"] = intent;
        _searchParametersCopy["entity"] = sanitizedInput;
        _searchParametersCopy["report"] = null;
      }

      if ("report" === intent) {
        switch(userType) {
          case("guest"):
            if (sanitizedInput !== youngestReportPeriod.current.slice(0, 10)) {
              // Guest user has asked for a report that isn't the current day
              // Don't update search parameters' `report` key - i.e. stay with the current day report
              _notification = {
                "category": "account",
                "notice": "upgradeRequestNoAccountHistReport",
                "options": []
              }
            }
            break;
          case("basic"):
            // Basic users can access the current day report as well as 3 months of historical reports
            let _reportDate = DateUtils.newDateObject(null, null, null, null, sanitizedInput, "underscore-year-first");           
            let _earliestAllowedDate = new Date(DateUtils.subtractDaysFromDateObject(new Date(), USER_PARAMS["basic"]["allowed_reports_history"]));
            
            // DEBUG
            // console.group();
            //   console.log(_reportDate);
            //   console.log(_earliestAllowedDate);
            // console.groupEnd();
            // DEBUG
            
            if (_reportDate < _earliestAllowedDate) {
              // Basic user asked for a report outside the allowed window
              // Don't update search parameters `report` key - i.e. stay with the user's selected report before this one
              _notification = {
                "category": "account",
                "notice": "upgradeRequestBasicAccountHistReport",
                "options": []
              }
            } else {
              // Basic user asked for a report within allowed window
              _searchParametersCopy["report"] = sanitizedInput;
            }
            break;
          case("pro"):
            // Pro users have access to all reports
            _searchParametersCopy["report"] = sanitizedInput;
            break;
          default:
            // pass
            break;
        }
        _searchParametersCopy["intent"] = intent;
        _searchParametersCopy["entity"] = null;
      }

      _newSearchFieldValue = strings.search.input.label;
      return [_searchParametersCopy, _newSearchFieldValue, _notification]
    }

    const searchKeyDownHandler = (e) => {
      /**
       * The `searchKeyDownHandler` handles key down events.
       * 
       * @param {object} e The event object.
       * @return undefined
       * @catch {Error} err
       */
      try {
        if (e.key === "Enter") {
          searchSubmitHandler({}); // Call the submit handler method with empty event object
        }
      } catch (err) {
        console.error(err);
      }
    }

    const searchInputHandler = (e) => {
      /**
       * The `searchInputHandler` processes search bar events. This method sets the autocomplete list
       * based on a user's intent. 
       * 
       * @param {object} e The event object.
       * @return undefined
       * @catch {Error} err
       */
      try {     
        e.preventDefault();

        // Hide the calendar if it's currently displayed
        setCalendarVisible(0);

        let _inputValue = e.currentTarget.value;

        if ("focus" === e.type) {
          setSearchValue("");
        }

        if ("blur" === e.type) {
          if (document.getElementById(IDS.input.id).value === "") {
            resetSearchInput();
          }
        }

        if ("input" === e.type) {
          let _items = [];
          let _reportPeriods = allReportPeriods.current;
          // let _articles = allArticles.current;

          // Check if user is searching by ticker or firm name
          if (((SEARCH_INPUT_REGEX["TICKER"].test(_inputValue)) && (_inputValue.length <= 7)) || SEARCH_INPUT_REGEX["FIRMNAME"].test(_inputValue)) {
            for (let _i = 0; _i < allTickers.current.length; _i++) {
              if ((allTickers.current[_i][0].substring(0, _inputValue.length).toUpperCase() === _inputValue.toUpperCase()) || (allTickers.current[_i][1].substring(0, _inputValue.length).toUpperCase() === _inputValue.toUpperCase())) {
                _items.push({"intent": "entity", "value": allTickers.current[_i][0], "display": allTickers.current[_i][0], "name": allTickers.current[_i][1]});
              }
            }

            // Check if any articles match this ticker and add those to autocomplete list
            // for (let _key in _articles) {
            //   let _tags = _articles[_key]["tags"];
            //   if (_tags.indexOf(_inputValue) > -1) {
            //     _items.unshift({"intent": "article", "value": _articles[_key]["title"], "display": _articles[_key]["title"]});
            //   }
            // };
          } 
          
          // Check if user is searching by CIK
          if (SEARCH_INPUT_REGEX["CIK"].test(_inputValue) && (_inputValue.length > 3) && (_inputValue.length <= 10)) {
              for (let _c = 0; _c < allCIKs.current.length; _c++) {
                if (allCIKs.current[_c][0].substring(0, _inputValue.length) === _inputValue.toString()) {
                  _items.push({"intent": "entity", "value": allCIKs.current[_c][0], "display": allCIKs.current[_c][0], "name": `(${allCIKs.current[_c][1]})`});
                }
              }
          }
          
          // Check if user is searching for a report
          if ((SEARCH_INPUT_REGEX["DATE1"].test(_inputValue)) || (SEARCH_INPUT_REGEX["DATE2"].test(_inputValue)) || (SEARCH_INPUT_REGEX["REPORT3"].test(_inputValue))) {
              for (let _p = 0; _p < _reportPeriods.length; _p++) {
                _items.push({"intent": "report", "value": _reportPeriods[_p], "display": prettifyReportName(_reportPeriods[_p]), "name": ""})
              }
              _items = _items.sort((a, b) => {
              let dateA = new Date(a["value"].slice(0, 4), a["value"].slice(5, 7), a["value"].slice(8, 10));
              let dateB = new Date(b["value"].slice(0, 4), b["value"].slice(5, 7), b["value"].slice(8, 10));
              if (dateA < dateB) {
                return -1
              } else if (dateA > dateB) {
                return 1
              } else {
                return 0
              }
            })
          }

          // Update state
          autoCompleteItems.current = _items;
          setAutoCompleteItemsVisible(1);
          setSearchValue(_inputValue);
        }
      } catch (err) {
        console.error(err);
      }
    }

    const autoCompleteItemClickHandler = (e) => {
      /**
       * The `autoCompleteItemClickHandler` method processes click events on items in the autocomplete
       * list.
       * 
       * @param {object} e The event object.
       * @return undefined
       * @catch {Error} err
       */
      try {
        // Get the autocomplete item `value` and `intent` attributes
        let _itemValue = e.currentTarget.attributes.getNamedItem("data-autocomplete-item-value").value;
        let _itemIntent = e.currentTarget.attributes.getNamedItem("data-autocomplete-item-intent").value;
        setSearchValue(_itemValue);
        searchSubmitHandler({ "intent": _itemIntent, "value": _itemValue });
      } catch (err) {
        console.error(err);
      }
    }

    const calendarIconHandler = () => {
      /**
       * The `calendarIconHandler` toggles visibility of the calendar widget. The widget can only be
       * displayed when viewing reports.
       * 
       * @param undefined
       * @return undefined
       */
      if ("report" === searchParameters["intent"]) {
        if (0 === calendarVisible) {
          setCalendarVisible(1);
        } else {
          setCalendarVisible(0);
        }
      }
    }

    const calendarChangeHandler = (e) => {
      /**
       * The `calendarChangeHandler` processes calendar input submissions.
       * 
       * @param {object} e The event object from the calendar which is a JavaScript `Date` object.
       * @return undefined
       * @catch {Error} err
       */
      try {
        let dateString = DateUtils.getDatePartAsString(e, "underscore-year-first");
        let inputObj = {
          intent: "report",
          value: dateString
        }

        // Hide the calendar widget
        setCalendarVisible(0);

        // Call the search submit handler
        searchSubmitHandler(inputObj);
      } catch (err) {
        console.error(err);
      }
    }

    const searchSubmitHandler = (e) => {     
      /**
       * The `searchSubmitHandler` processes search input submissions.
       * 
       * @param {object} e The event object.
       * @return undefined
       * @catch {Error} err
       */
      try {
        let _intent;
        let _inputValue;
        let _sanitizedInput;
        let _match;
        
        // Determine the type of submission: autocomplete or user input
        if (e.hasOwnProperty("intent")) {
          // This is an autocomplete or calendar submission
          _intent = e.intent;
          _sanitizedInput = e.value.toLowerCase();
        } else {
          // This is a user generated input submission
          _inputValue = document.getElementById(IDS.input.id).value.toLowerCase();      
          [_intent, _sanitizedInput, _match] = parseUserInputAdvanced(_inputValue);

          // DEBUG
          console.log(_intent);
          console.log(_sanitizedInput);
          console.log(_match);
          // DEBUG
        }

        // Conditional logic based on user's intent
        switch (_intent) {
          case("article"):
            let _articles = allArticles.current;
            let _slug;
            // Get slug for this article
            for (let _key in _articles) {
              if (_sanitizedInput === _articles[_key]["title"].toLowerCase()) {
                _slug = _articles[_key]["slug"];
              }
            }
            navigate(process.env.REACT_APP_PATH + "/articles/" + _slug); // Navigate to the article route
            break;
          case("entity"):
          let _entityExists;
          let _entityTicker;  
          if (_match === "FIRMNAME") {
              [_entityExists, _entityTicker] = checkIfEntityExists(_sanitizedInput)
            } else {
              _entityExists = checkIfEntityExists(_sanitizedInput);
            }
            if (_entityExists) {
              let [_searchParametersCopy, _newSearchFieldValue, _notification] = processSearchByAuth(_intent, (_entityTicker) ? _entityTicker : _sanitizedInput, searchParameters);
              setSearchParameters(_searchParametersCopy);
              setSearchValue(_newSearchFieldValue);
              setNotification(_notification);
            }
            break;
          case("report"):
            if (checkIfReportExists(_sanitizedInput)) {
              let [_searchParametersCopy, _newSearchFieldValue, _notification] = processSearchByAuth(_intent, _sanitizedInput, searchParameters);
              
              // console.group();
              //   console.log(_searchParametersCopy);
              //   console.log(_newSearchFieldValue);
              //   console.log(_notification);
              // console.groupEnd();
              
              setSearchParameters(_searchParametersCopy);
              setSearchValue(_newSearchFieldValue);
              setNotification(_notification);
            }
            break;
          default:
            // pass
            break;
        }
        
        // Hide autocomplete list
        setAutoCompleteItemsVisible(0);
      } catch (err) {
        console.error(err);
      }
    }

    const advancedSearchVisibleHandler = () => {
      /**
       * The `advancedSearchVisibleHandler` method toggles `isAdvancedSearchVisible`.
       * 
       * @param undefined
       * @return undefined
       */
      if (0 === isAdvancedSearchVisible) {
        setIsAdvancedSearchVisible(1);
      } else {
        setIsAdvancedSearchVisible(0);
      }
    }

    useEffect(() => {
        document.getElementById(IDS.input.id).value = searchValue;
    }, [searchValue])

    if (("" === searchValue) || (searchStrings.input["label"] === searchValue) || (0 === autoCompleteItemsVisible)) {
        return(
          <React.Fragment>
            <div id = { IDS.area.id } className = { [styles.searchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-center align-items-center">
                    <div className = "col-md-5">
                        <div id = { IDS.group.id } className = "input-group mb-3 mt-3">
                            <button className = { [styles.inputButton, "btn", "btn-outline-secondary"].join(" ") } type = "button" onClick = { (e) => advancedSearchVisibleHandler(e) }>
                                <FontAwesomeIcon icon = { searchStrings["filter"]["icon"] }></FontAwesomeIcon>
                                <span className = { styles.inputLabel }>{ searchStrings["filter"]["label"] }</span>
                            </button>
                            <input id = { IDS.input.id } type = "text" autoComplete = "off" maxLength = "20" className = { [styles.inputField, "form-control"].join(" ") } placeholder = { strings.search["placeholder"]["label"] } aria-label = { strings.search["placeholder"]["label"] } onBlur = { (e) => searchInputHandler(e) } onFocus = { (e) => searchInputHandler(e) } onInput = { (e) => searchInputHandler(e) } onKeyDown = { (e) => searchKeyDownHandler(e) }></input>
                            <span className = { (searchParameters["intent"] === "report") ? [styles.inputIcon, "input-group-text"].join(" ") : [styles.inputIconDisabled, "input-group-text"].join(" ") } onClick = { () => calendarIconHandler() }><FontAwesomeIcon icon = { searchStrings["calendar"]["icon"] }></FontAwesomeIcon></span>
                            <span className = { [styles.inputIcon, "input-group-text"].join(" ") } onClick = { (e) => searchSubmitHandler(e) }><FontAwesomeIcon icon = { searchStrings["search"]["icon"] }></FontAwesomeIcon></span>
                            {
                              (1 === calendarVisible) && <div className = { styles.calendar }><Calendar onChange = {(e) => { calendarChangeHandler(e) }}></Calendar></div>
                            }      
                        </div>
                    </div>
                </div>
            </div>
            <AdvancedSearchArea
              DEFAULT_SEARCH_PARAMETERS = { DEFAULT_SEARCH_PARAMETERS }
              searchIds = { IDS }
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              setSearchParameters = { setSearchParameters }
              parseUserInputAdvanced = { parseUserInputAdvanced }
              allIndustries = { allIndustries }
              strings = { strings }>
            </AdvancedSearchArea>            
          </React.Fragment>
        )
    } else {
        return(
          <React.Fragment>
            <div id = { IDS.area.id } className = { [styles.searchAreaContainer, "container-fluid"].join(" ") }>
                <div className = "row justify-content-center align-items-center">
                    <div className = "col-md-5">
                        <div id = { IDS.group.id } className = "input-group mb-3 mt-3">
                            <button className = "btn btn-outline-secondary" type = "button" onClick = { (e) => advancedSearchVisibleHandler(e) }>
                                <FontAwesomeIcon className = { styles.inputIcon } icon = { searchStrings["filter"]["icon"] }></FontAwesomeIcon>
                                <span className = { styles.inputLabel }>{ searchStrings["filter"]["label"] }</span>
                            </button>
                            <input id = { IDS.input.id } type = "text" autoComplete = "off" maxLength = "20" className = { [styles.inputField, "form-control"].join(" ") } placeholder = { strings.search["placeholder"]["label"] } aria-label = { strings.search["placeholder"]["label"] } onBlur = { (e) => searchInputHandler(e) } onFocus = { (e) => searchInputHandler(e) } onInput = { (e) => searchInputHandler(e) } onKeyDown = { (e) => searchKeyDownHandler(e) }></input>
                            <span className = { (searchParameters["intent"] === "report") ? [styles.inputIconDisabled, "input-group-text"].join(" ") : [styles.inputIcon, "input-group-text"].join(" ") } onClick = { () => calendarIconHandler() }><FontAwesomeIcon icon = { searchStrings["calendar"]["icon"] }></FontAwesomeIcon></span>
                            <span className = { [styles.inputIcon, "input-group-text"].join(" ") } onClick = { (e) => searchSubmitHandler(e) }><FontAwesomeIcon icon = { searchStrings["search"]["icon"] }></FontAwesomeIcon></span>
                            <div className = { styles.autoCompleteItems } onMouseLeave = {() => { resetSearchInput() }}>
                                {
                                    autoCompleteItems.current.map((item, i) => {
                                        if ("article" !== item.intent) {
                                          return(
                                              <div key = {`autocomplete-item-${i}`} data-autocomplete-item-intent = { item.intent } data-autocomplete-item-value = { item.value } onClick = {(e) => autoCompleteItemClickHandler(e)}><FontAwesomeIcon icon = { searchStrings["autocomplete"][item.intent]["icon"] } />&nbsp;&nbsp;{`${item.display} ${item.name}`}</div>
                                          )
                                        } else {
                                          return(
                                            <div key = {`autocomplete-item-${i}`} data-autocomplete-item-intent = { item.intent } data-autocomplete-item-value = { item.value } onClick = {(e) => autoCompleteItemClickHandler(e)}><FontAwesomeIcon icon = { searchStrings["autocomplete"][item.intent]["icon"] } />&nbsp;&nbsp;{`${item.display}`}</div> 
                                          )
                                        }
                                    })
                                }
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
            <AdvancedSearchArea
              DEFAULT_SEARCH_PARAMETERS = { DEFAULT_SEARCH_PARAMETERS }
              searchIds = { IDS }
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              setSearchParameters = { setSearchParameters }
              parseUserInputAdvanced = { parseUserInputAdvanced }
              allIndustries = { allIndustries }
              strings = { strings }>
            </AdvancedSearchArea>            
          </React.Fragment>
        )
    }
}

export default SearchArea