import { useContext, useEffect, useState } from "react";
import { UserContext } from "./user-context";
import ReportTable from "./table-report";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import styles from "./table.module.css";
import { DateUtils, StringUtils, WebUtils } from "../scripts/utils";
import services from "../scripts/services";
// import { faBriefcaseClock } from "@fortawesome/free-solid-svg-icons";

const isDictionaryEmpty = (data) => {
    let _emptyFlag = false;
    let _key;

    for (_key in data) {
        let _report = data[_key];
        if (Object.keys(_report).length === 0) {
            _emptyFlag = true;
        }
    }

    return _emptyFlag
}

const Reports = ({ SEARCH_INPUT_REGEX, isAdvancedSearchVisible, searchParameters, allIndustries, strings, isMobile, youngestReportPeriod }) => {
    // Get context
    const userContext = useContext(UserContext);
    const user = userContext.FBUser;
    const isProUser = userContext.isPro;
    const justUpgraded = userContext.justUpgraded;
    const justVerified = userContext.justVerified;
    const userLang = userContext.userLang;

    const [reportsDoc, setReportsDoc] = useState(null);

    // Set translation strings
    let translationLocale;
    switch (userLang) {
        case ("es"):
            translationLocale = "es-MX";
            break;
        default:
            translationLocale = "en-US";
            break;
    }

    let _report = searchParameters["report"];

    // Set string representation of report date based on user's locale
    let _reportDateObj = null;
    let _reportDateFull = null;
    if (null !== _report) {
        _reportDateObj = DateUtils.newDateObject(null, null, null, null, _report, "underscore-year-first");
        _reportDateFull = _reportDateObj.toLocaleString(translationLocale, { weekday: "long", year: "numeric", month: "short", day: "numeric"});    
    }

    useEffect(() => {
        if (null !== _report) {
            // Get the user access token
            let _token;
            try {
                _token = user.accessToken
            } catch {
                _token = "none"
            }

            // Get the user's email verification status
            let _isVerified;
            try {
                _isVerified = user.emailVerified;
            } catch {
                _isVerified = false;
            }

            // Set the command string
            let _command = {
                "action": "get-reports",
                "report-period": _report,
                "token": _token,
                "is-verified": _isVerified,
                "is-pro-user": isProUser,
                "just-verified": justVerified,
                "just-upgraded": justUpgraded
            }
            let _command_str = JSON.stringify(_command);

            WebUtils.request(services["webServices"], _command_str)
            .then((_data) => {
                if (_data) {                   
                    _data = StringUtils.cleanCRNL(_data);
                    _data = JSON.parse(_data);

                    // DEBUG
                    // console.log(_data);
                    // DEBUG

                    if (_data.hasOwnProperty("error")) {
                        if ("user not authorized" === _data["error"]) {
                            setReportsDoc(false);
                        }
                        else {
                            console.error(_data["error"]);
                            setReportsDoc(false);
                        }
                    } else {
                        if (!isDictionaryEmpty(_data)) {
                            // Report was returned with data
                            setReportsDoc(_data);                            
                        } else {
                            // Report was returned with no data
                            setReportsDoc(false);                            
                        }                     
                    }
                } else {
                    // `null` will be returned if request failed
                    setReportsDoc(false);
                }
            })
            .catch((err) => console.error(err));
        }

        return () => {
            setReportsDoc(null);
        };
    }, [_report, user, isProUser, justVerified, justUpgraded]);
    
    // No valid `_report` period is available
    if (null === _report) {
        return(
            <div className = { styles.loadingMessage }>{ strings.reportMessages.waiting["label"] }</div>
        )
    }
    
    // There is a valid `_report` period, but `reportsDoc` is `null` (original state); report is loading
    if (null === reportsDoc) {
        return(
            <div className = { styles.loadingMessage }>{ strings.reportMessages.loading["label"].replace("$", _reportDateFull) }</div>
        )
    }

    // The user is allowed to view the report for the specified period but no report data was received
    if (!reportsDoc) {
        return(
            <div className = { styles.loadingMessage }>{ strings.reportMessages.noData["label"].replace("$", _reportDateFull) }</div>
        )
    }    
    
    // The user is allowed to view the report for the specified period and report data was received
    if (reportsDoc) {
        // Set default tab to first report in `reportsDoc`
        let _defaultTab = Object.keys(reportsDoc)[0];                
                    
        return(
            <Tabs defaultActiveKey = { _defaultTab }>
                {
                    Object.keys(reportsDoc).map((report) => {
                        let _tabTitle;
                        if (isMobile) {
                            _tabTitle = strings.tableTitles.report[report]["abbr"];
                        } else {
                            _tabTitle = strings.tableTitles.report[report]["full"];
                        }
                        return(
                            <Tab key = { report } eventKey = { report } title = { _tabTitle }>
                                <ReportTable
                                    SEARCH_INPUT_REGEX = { SEARCH_INPUT_REGEX }
                                    tabTitle = { _tabTitle }
                                    report = { report }
                                    data = { reportsDoc[report] }
                                    isAdvancedSearchVisible = { isAdvancedSearchVisible }
                                    searchParameters = { searchParameters }
                                    allIndustries = { allIndustries }
                                    strings = { strings }
                                    isMobile = { isMobile }
                                    youngestReportPeriod = { youngestReportPeriod }
                                    userLang = { userLang }>
                                </ReportTable>
                            </Tab>
                        )
                    })
                }
            </Tabs>
        )
    }
}

export default Reports