import { Fragment, useState } from "react";
import ReportTableDataWrapper from "./table-report-data-wrapper";
import Form4Modal from "./form4-filing-modal";
import { WebUtils } from "../scripts/utils";
import services from "../scripts/services";

const ReportTableRowWrapper = ({ rowData, rowIndex, isMobile, report, strings }) => {
    // DEBUG
    // console.log(rowData);
    // DEBUG
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form4Data, setForm4Data] = useState(null);

    const toggleModal = () => {
        if (true === isModalVisible) {
            // Reset `form4Data` to `null`
            setForm4Data(null);
        }        
        setIsModalVisible((state) => !state);
    }    
    
    const reportTableRowClickHandler = (e, accessionNum, issuerTicker, issuer) => {
        // DEBUG
        // console.log(accessionNum);
        // DEBUG
        
        // Strip suffix from report accession number
        let lastHyphenIndex = accessionNum.lastIndexOf("-");
        accessionNum = accessionNum.slice(0, lastHyphenIndex);

        let _command = {
            action: "get-form-4-data",
            accessionNum: accessionNum,
            ticker: issuerTicker,
            filer: issuer
        }
        let _command_str = JSON.stringify(_command);
        WebUtils.request(services.webServices, _command_str)
        .then((response) => {
            let _data = JSON.parse(response);
            // DEBUG
            console.log(_data);
            // DEBUG

            if ("error" in _data) {
                return false
            } else {
                // Append accession number to `_data`
                _data["accession_number"] = accessionNum;

                setForm4Data(_data);
                return true
            }
        })
        .then((isDataSet) => {
            if (isDataSet) {
                setIsModalVisible(true);
            }
            // if `isDataSet` === `false`, don't display the modal; an error occured.
        })
        .catch(console.error);
    }
    
    return(
        <Fragment>
            <ReportTableDataWrapper
                rowData = { rowData }
                rowIndex = { rowIndex }
                isMobile = { isMobile }
                report = { report }
                handlers = {{ click: reportTableRowClickHandler }}>
            </ReportTableDataWrapper>
            {
                (null !== form4Data) && <Form4Modal show = { isModalVisible } handlers = {{ toggle: toggleModal }} form4Data = { form4Data } strings = { strings }></Form4Modal>
            }
        </Fragment>
    )
}

export default ReportTableRowWrapper