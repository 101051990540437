const strings = {
    seo: {
        companySuffix: "Inveyo",
        title: "Evaluador del Form 4",
        metaDesc: "Inveyo le permite investigar los formularios 4 del SEC. Busque por fecha actual o histórica, o por símbolo de acción para más de 6,000 acciones."
    },
    universals: {
        timeSuffixes: { "am": "AM", "pm": "PM" },
        timeZones: { "est": "EST" },
        onTheHourMinutes: ":00",
        notAvailable: "ninguna"
    },
    logoAreaBranding: {
        name: "inveyo",
        shortName: "inveyo",
        version: "beta",
        tagline: "evaluador del form 4"
    },
    logoAreaNav: {
        nav1: { "label": "menu", "icon": "fa-solid fa-bars", "navKey": "side-panel-menu" },
        nav2: { "label": "idioma", "icon": "fa-solid fa-globe", "navKey": "language" }
    },
    logoAreaUser: {
        nav1: { "label": "entrar", "icon": "fa-solid fa-right-to-bracket", "navKey": "login" },
        nav2: { "label": "inscribirse", "icon": "fa-solid fa-user-plus", "navKey": "signup" },
        nav3: { "label": "verificar", "icon": "fa-solid fa-envelope-open-text", "navKey": "verify" },
        nav4: { "label": "salir", "icon": "fa-solid fa-right-from-bracket", "navKey": "logout" },
        nav5: { "label": "ajustes", "icon": "fa-solid fa-cog", "navKey": "settings" }
    },
    subNav: {
        nav1: { "label": "evaluador del form 4", "icon": "", "navKey": "form-4-screener" },
        nav2: { "label": "artículos", "icon": "", "navKey": "articles" }
    },
    sideNav: {
        nav1: { "label": "inicio", "icon": "fa-solid fa-home", "navKey": "home" },
        nav2: { "label": "leyenda", "icon": "fa-solid fa-list", "navKey": "legend" },
        nav3: { "label": "ajustes", "icon": "fa-solid fa-cog", "navKey": "settings" },
        // nav4: { "label": "misión", "icon": "fa-solid fa-flag", "navKey": "mission" },
        nav5: { "label": "ayuda", "icon": "fa-solid fa-info-circle", "navKey": "help" },
        nav6: { "label": "contáctenos", "icon": "fa-solid fa-person-circle-question", "navKey": "contact-us" }
    },
    settingsNav: {
        nav1: { "label": "cerrar", "icon": "", "navKey": "close" },
        nav2: { "label": "perfil", "icon": "fa-solid fa-user", "navKey": "profile" },
        nav3: { "label": "plan", "icon": "fa-solid fa-file-invoice", "navKey": "plan" }
    },
    articlesNav: {
        nav1: { "label": "todo", "icon": "", "navKey": "all" },
        nav2: { "label": "deba leer", "icon": "", "navKey": "must" }     
    },
    legend: {
        title: { "label": "leyenda del código de transacción" },
        colorCodes: {
            header: { "label": "códigos de color" },
            acqNonDeriv: { "key": "verde", "label": "adquisición o compra no derivada" },
            acqDeriv: { "key": "azul", "label": "adquisición o compra derivada" },
            disNonDeriv: { "key": "naranja", "label" : "disposición o venta no derivada" },
            disDeriv: { "key": "rojo", "label": "disposición o venta derivada" },
            other: { "key": "blanco", "label": "otro" }
        },
        transactionCodes: {
            header: { "label": "código de transacción" },
            p: { "key": "p", "label": "compra de valores en el mercado abierto o privada" },
            s: { "key": "s", "label": "venta de valores en el mercado abierto o privado" },
            v: { "key": "v", "label": "transacción informada voluntariamente antes de lo requerido" },
            a: { "key": "a", "label": "subvención, premio u otra adquisición" },
            d: { "key": "d", "label": "venta (o disposición) de nuevo al emisor de los valores" },
            f: { "key": "f", "label": "pago del precio de ejercicio o de la obligación tributaria mediante la entrega o retención de valores" },
            i: { "key": "i", "label": "transacción discrecional al mejor precio posible" },
            m: { "key": "m", "label": "ejercicio o conversión de valores derivados" },
            c: { "key": "c", "label": "conversión de valores derivados" },
            e: { "key": "e", "label": "vencimiento de la posición corta en derivados" },
            h: { "key": "h", "label": "vencimiento de posición derivada larga" },
            o: { "key": "o", "label": "ejercicio de valores derivados fuera del dinero" },
            x: { "key": "x", "label": "ejercicio o valores derivados en el dinero o en el dinero" },
            g: { "key": "g", "label": "regalo" },
            l: { "key": "l", "label": "pequeña adquisición" },
            w: { "key": "w", "label": "adquisición o disposición por testamento" },
            z: { "key": "z", "label": "depósito o retiro del fideicomiso de votación" },
            j: { "key": "j", "label": "otra adquisición o disposición" },
            k: { "key": "k", "label": "transacción en swap de acciones o instrumento similar" },
            u: { "key": "u", "label": "enajenación por oferta de acciones en una operación de cambio de control" }            
        }
    },
    search: {
        filter: { "label": "filtrar", "icon": "fa-solid fa-filter" },
        calendar: { "label": "", "icon": "fa-solid fa-calendar" },
        search: { "label": "", "icon": "fa-solid fa-magnifying-glass" },
        input: { "label": "" },
        placeholder: { "label": "busca" },
        autocomplete: { "entity": { "icon": "fa-solid fa-building" }, "report": { "icon": "fa-solid fa-file-text" }, "article": { "icon": "fa-solid fa-newspaper" }},
        prettyReportName: { "prefix": "informes para" }
    },
    advancedSearch: {
        entityOrReports: { "label": "símbolo/CIK/informe", "icon": "", "placeholder": "símbolo, CIK o informe" },
        sharePriceMin: { "label": "precio de acción ($)", "icon": "", "placeholder": "mín" },
        sharePriceMax: { "label": "", "icon": "", "placeholder": "máx" },
        liquidityMin: { "label": "valor de transacción ($)", "icon": "", "placeholder": "mín" },
        liquidityMax: { "label": "", "icon": "", "placeholder": "máx" },
        industry: { "label": "industria", "icon": "", "placeholder": "" },
        filingDate: { "label": "fecha de archivado", "icon": "", "placeholder": "" },
        security: { "label": "título de seguridad", "icon": "", "placeholder": "" },
        owner: { "label": "título del dueño", "icon": "", "placeholder": "" },
        ownerName: { "label": "nombre del dueño", "icon": "", "placeholder": "nombre" },
        transaction: { "label": "transaction type", "icon": "" },
        // transactionCodeButtons: [
        //    { "code": "a", "label": "acquisition" },
        //    { "code": "d", "label": "disposition" },
        //    { "code": "m", "label": "option" },
        //    { "code": "i", "label": "individual" },
        //    { "code": "j", "label": "joint" } 
        // ],
        transactionCodeButtons: [
            { "code": "p", "label": "compra" },
            { "code": "s", "label": "venta" },
            { "code": "a", "label": "adquisición" },
            { "code": "d", "label": "disposición" },
            { "code": "m", "label": "derivado" } 
        ],
        submitButton: { "label": "enviar" },
        resetButton: { "label": "reiniciar" }
    },
    filingDateOptions: {
        "all dates": { "abbr": "todo", "long": "todas fechas" },
        "latest day": { "abbr": "último", "long": "último día" },
        "latest 3 days": { "abbr": "últimos 3", "long": "últimos 3 días" },
        "last 1 week": { "abbr": "última semana", "long": "última semana" },
        "last 1 year": { "abbr": "último año", "long": "último año" },
        // "last 2 years": { "abbr": "últimos 2 años", "long": "últimos 2 años" },
        // "last 3 years": { "abbr": "últimos 3 años", "long": "últimos 3 años" },
        // "last 4 years": { "abbr": "últimos 4 años", "long": "últimos 4 años" },
        // "last 5 years": { "abbr": "últimos 5 años", "long": "últimos 5 años" }
    },
    tradeDateOptions: {
        "all dates": { "abbr": "todo", "long": "todas fechas" },
        "latest day": { "abbr": "último", "long": "último día" },
        "latest 3 days": { "abbr": "últimos 3", "long": "últimos 3 días" },
        "last 1 week": { "abbr": "última semana", "long": "última semana" },
        "last 1 year": { "abbr": "último año", "long": "último año" },
        // "last 2 years": { "abbr": "últimos 2 años", "long": "últimos 2 años" },
        // "last 3 years": { "abbr": "últimos 3 años", "long": "últimos 3 años" },
        // "last 4 years": { "abbr": "últimos 4 años", "long": "últimos 4 años" },
        // "last 5 years": { "abbr": "últimos 5 años", "long": "últimos 5 años" }
    },
    securityOptions: {
        "all": { "abbr": "all", "long": "todo", "strings": [] },
        "common stock": { "abbr": "stock", "long": "acciones comunes", "strings": ["common stock"] },
        "restricted stock unit": { "abbr": "rsu", "long": "unidad de acciones restringidas", "strings": ["restricted stock unit", "rst. stock unit", "RSU"] },
        "phantom stock unit": { "abbr": "psu", "long": "unidad de stock fantasma", "strings": ["phantom stock unit", "PSU"] },
        "deferred stock unit": { "abbr": "dsu", "long": "unidad de acciones diferidas", "strings": ["deferred stock unit", "DSU"] },
        "stock option": { "abbr": "option", "long": "opción sobre acciones", "strings": ["stock option", "employee stock option", "emp. stock option"] },
        "promised fee share": { "abbr": "pfs", "long": "acción de compartida prometida", "strings": ["promised fee share"] }
    },
    ownerOptions: {
        "all": { "abbr": "todos", "long": "todos", "strings": [] },
        "chief executive officer": { "abbr": "CEO", "long": "director ejecutivo (CEO)", "strings": ["ceo", "chief executive officer"] },
        "president": { "abbr": "pres", "long": "presidente", "strings": ["pres", "president"] },
        "chief operating officer": { "abbr": "COO", "long": "director de operaciones (COO)", "strings": ["coo", "chief operating officer"] },
        "chief financial officer": { "abbr": "CFO", "long": "director financiero (CFO)", "strings": ["cfo", "chief financial officer"] },
        "executive vice president": { "abbr": "EVP", "long": "vicepresidente ejecutivo (EVP)", "strings": ["evp", "executive vice president"] },
        "senior vice president": { "abbr": "SVP", "long": "vicepresidente senior (SVP)", "strings": ["svp", "sr. vp", "senior vice president"] },
        "vice president": { "abbr": "VP", "long": "vicepresidente (VP)", "strings": ["vp", "vice president"] },
        "general counsel": { "abbr": "GC", "long": "asesor general", "strings": ["gc", "general counsel"] },
        "director": { "abbr": "DIR", "long": "director", "strings": ["dir", "director"] },
        "10% owner": { "abbr": "10%", "long": "10% propietario", "strings": ["10%", "10% owner"] }
    },
    entityTableHeaders: {
        entityTableHeaders1: { "label": "fecha de archivado", "abbr": "date", "icon": "", "objName": "filingDate" },
        entityTableHeaders2: { "label": "fecha de transacción", "icon": "", "abbr": "trade", "objName": "earliestTransactionDate" },
        entityTableHeaders3: { "label": "símbolo", "icon": "", "abbr": "ticker", "objName": "issuerTicker" },
        entityTableHeaders4: { "label": "persona", "icon": "", "abbr": "person", "objName": "reportingPerson" },
        entityTableHeaders5: { "label": "título", "icon": "", "abbr": "title", "objName": "reportingPersonTitle" },
        entityTableHeaders6: { "label": "individuo o grupo", "icon": "", "abbr": "type", "objName": "individualFilingCode" },
        entityTableHeaders7: { "label": "título de seguridad", "icon": "", "abbr": "security", "objName": "securityTitle" },
        entityTableHeaders8: { "label": "transacción", "icon": "", "abbr": "code", "objName": "transactionCode" },
        entityTableHeaders9: { "label": "precio", "icon": "", "abbr": "price", "objName": "price" },
        entityTableHeaders10: { "label": "cantidad", "icon": "", "abbr": "qty", "objName": "amount" },
        entityTableHeaders11: { "label": "valor", "icon": "", "abbr": "value", "objName": "totalValue" },
        entityTableHeaders12: { "label": "cantidad poseída", "icon": "", "abbr": "owned", "objName": "amountOwned" },
        entityTableHeaders13: { "label": "cambio", "icon": "", "abbr": "change", "objName": "delta" }
    },
    reportTableHeaders: {
        reportTableHeaders1: { "label": "fecha de aceptación", "icon": "", "abbr": "date", "objName": "acceptanceDate" },
        reportTableHeaders2: { "label": "tiempo de aceptación", "icon": "", "abbr": "time", "objName": "acceptanceTime" },
        reportTableHeaders3: { "label": "símbolo", "icon": "", "abbr": "ticker", "objName": "issuerTicker" },
        reportTableHeaders4: { "label": "emisor", "icon": "", "abbr": "issuer", "objName": "issuer" },
        reportTableHeaders5: { "label": "industria", "icon": "", "abbr": "ind", "objName": "industry" },
        reportTableHeaders6: { "label": "persona", "icon": "", "abbr": "person", "objName": "reportingPerson" },
        reportTableHeaders7: { "label": "título", "icon": "", "abbr": "title", "objName": "reportingPersonTitle" },
        reportTableHeaders8: { "label": "precio", "icon": "", "abbr": "price", "objName": "price" },
        reportTableHeaders9: { "label": "cantidad", "icon": "", "abbr": "qty", "objName": "amount" },
        reportTableHeaders10: { "label": "valor", "icon": "", "abbr": "value", "objName": "totalValue" },
        reportTableHeaders11: { "label": "cantidad poseída", "icon": "", "abbr": "owned", "objName": "amountOwned" },
        reportTableHeaders12: { "label": "cambio", "icon": "", "abbr": "change", "objName": "delta" }
    },
    entityMessages: {
        waiting: { "label": "Un minuto... obteniendo datos del Form 4..." },
        loading: { "label": "Ahora cargando datos para $..." },
        noData: { "label": "No hay datos para $..." }
    },
    reportMessages: {
        waiting: { "label": "Un minuto... obteniendo datos del Formulario 4..." },
        loading: { "label": "Ahora cargando datos para el informe con periodo $..." },
        noData: { "label": "No hay informe para el periodo $..." }
    },
    articleMessages: {
        waiting: { "label": "Un minuto...obteniendo artículos..." }
    },
    articleBadges : {
        must: { "label": "deba leer", "icon": "" },
        new: { "label": "nuevo", "icon": "" },
        premium: { "label": "pro", "icon": "" },
        noBadge: { "label": "", "icon": "" }
    },
    articleTitles: {
        author: "autor",
        publishDate: "fecha de publicación original"
    },    
    tableTitles: {
        entity: {
            titleTemplate: "datos de form 4 de $1 ($2)",
            subTitleTemplate: "(CIK: $1, Industria: $2)"
        },
        report: {
            titleTemplate: "$2 $1",
            subTitleTodayTemplate: "(Datos de $1; próxima obtención a las $2)",
            subTitleEODTemplate: "(Datos de las $1; próxima obtención el $2 a las $3)",
            subTitleNewDayTemplate: "(Próxima obtención el $1 a las $2)",
            "nd-acq": { "full": "compras recientes", "abbr": "compras" },
            "nd-acq-100": { "full": "compras recientes $100K+", "abbr": "compras $100K+" },
            "nd-dis": { "full": "ventas recientes", "abbr": "ventas" },
            "nd-dis-100": { "full": "ventas recientes $100K+", "abbr": "ventas $100K+" }
        }
    },
    notifications: {
        account: {
            notVerified: "verifique su dirección de email. por favor examine su email para una email de verificación. si necesite otro email de verificación, haga click el botón `Verificar` arriba.",
            sentVerification: "acabamos de enviarle un email de verificación. haga click el enlace dentro del email para verificar su cuenta.",
            justVerified: "gracias por verificar su email!",
            upgradeRequestNoAccountLimitedData: "¿Quiere ver más datos de Form 4 para $1? Click `Inscribirse` para crear una cuenta gratis.",
            upgradeRequestNoAccountHistReport: "Puede ver informes históricos con una cuenta gratis. Click `Inscribirse` y eliga el plan Básica.",
            upgradeRequestBasicAccountLimitedData: "Vea todos los datos para $1 con una cuenta `Pro`. Puede cambiar su cuenta por `Ajustes` > `Plan`.",
            upgradeRequestBasicAccountHistReport: "Puede ver solamente 3 meses de informes históricos con una cuenta gratis. Cambia al plan Pro por `Ajustes` > `Plan`."
        }
    },
    formContent: {
        passwordInputs: {
            passwordLabel: "Entre una contraseña",
            passwordPlaceholder: "contraseña",
            confirmLabel: "Confirme su contraseña",
            confirmPlaceholder: "confirme contraseña",
            currentLabel: "entre su contraseña actual",
            currentPlaceholder: "contraseña",
            newLabel: "entre su contraseña nueva",
            newPlaceholder: "contraseña",
            passwordHint: "1 letra mayuscula, 1 número, y entre 8 y 16 letras."
        },
        otherInputs: {
            emailLabel: "Entre su email",
            emailPlaceholder: "sunombre@ejemplo.com",
            couponLabel: "Si tiene un código, entrélo aquí.",
            couponPlaceholder: "código"
        },
        buttons: {
            submitLabel: "Enviar",
            submitChoosePlanLabel: "Próximo: Eliga Plan", 
            submitPaymentLabel: "Próximo: Pago",
            submitDoneLabel: "Terminar",
            submitLoginLabel: "entrar",
            submitLogoutLabel: "salir",
            submitPayNow: "Pague Ahora",
            submitProcessing: "Enviando...",
            showUpdatePassword: "cambie contraseña",
            yes: "sí",
            no: "no"
        },
        text: {
            signup: {
                text1: "necesita una cuenta?",
                text2: "click aquí."
            },
            forgot: {
                text1: "se le olvidó su contraseña?",
                text2: "click aquí."
            },            
            backToLogin: {
                text1: "Ya tiene una cuenta?",
                text2: "Click para entrar."
            },
            loadingCheckout: {
                text1: "Cargando el formulario de caja. Por favor espere un momento.",
                text2: "Por favor espere un momento."
            }
        },
        plans: {
            all: {
                current: { "label": "su plan actual es $1."}
            },        
            basic: {
                core: { "label": "básico", "friendlyPrice": "gratis", "defaultPrice": "0", "interval": "mes", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "precio", "data": "$1/$2" },
                    start: { "label": "fecha de inicio", "data": "n/a" },
                    end: { "label": "fecha de finalización", "data": "n/a" }
                },
                options: {
                    upgradeNoPaymentMethod: { "label": "cambie a pro", "for": ["not-cancelling", "no-payment-method"], "optionKey": "upgrade-to-pro" },
                    upgradeWithPaymentMethod: { "label": "cambie a pro", "for": ["not-cancelling", "has-payment-method"], "optionKey": "upgrade-to-pro" }
                },
                features: {
                    reports: { "label": "informes de compra y venta", "permission": "día actual y los últimos 3 meses" },
                    filings: { "label": "form 4 empresarial", "permission": "datos limitados" },
                    form4Details: { "label": "form 4 detalle", "permission": "sí" }
                }
            },
            pro: {
                core: { "label": "pro", "friendlyPrice": "$39.95/mes", "defaultPrice": "3995", "interval": "mes", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "precio", "data": "$1/$2" },
                    start: { "label": "fecha de inicio", "data": "$1" },
                    end: { "label": "fecha de finalización", "data": "hasta cancelación. puede cancelar en cualquier momento." },
                    nextPayment: { "label": "próximo pago", "data": "$1" },
                },
                cancelText: {
                    at: "su suscripción cancelará en $1."
                },
                options: {
                    changePaymentMethod: { "label": "cambie tarjeta de crédito", "for": ["not-cancelling", "has-payment-method"], "optionKey": "change-payment-method" },
                    cancel: { "label": "cancele suscripción", "for": ["not-cancelling", "has-payment-method"], "optionKey": "cancel-subscription" },
                    reactivate: { "label": "reiniciar suscripción", "for": ["cancelling", "has-payment-method"], "optionKey": "reactivate-subscription-before-period-end" }
                },
                features: {
                    reports: { "label": "informes de compra y venta", "permission": "todos informes" },
                    filings: { "label": "form 4 empresarial", "permission": "datos completos" },
                    form4Details: { "label": "form 4 detalle", "permission": "sí" },
                    cancel: { "label": "cancelar sin problema", "permission": "sí" }
                }
            },
            trial: {
                core: { "label": "prueba gratis", "defaultPrice": "0", "interval": "mes", "defaultCurrency": "usd" },
                details: {
                    amount: { "label": "precio", "data": "$1/$2" },
                    start: { "label": "fecha de inicio", "data": "$1" },
                    end: { "label": "fecha de finalización", "data": "$1" },
                    afterTrial: { "label": "después de prueba gratis", "data": "$1" }
                },
                afterTrialText: {
                    invoice: "después de la prueba gratis, cargaremos su tarjeta de crédito $1/$2 empezando $3. puede cancelar en cualquier momento.",
                    cancel: "prueba gratis cancelará automáticamente. puede añadir un metodo de pago para continuar con una suscripción pagada."
                },
                options: {
                    addPaymentMethod: { "label": "añada tarjeta de crédito", "for": ["cancelling", "no-payment-method"], "optionKey": "add-payment-method" },
                    reactivate: { "label": "reiniciar suscripción después de prueba gratis", "for": ["cancelling", "has-payment-method"], "optionKey": "reactivate-subscription-before-period-end" },
                    changePaymentMethod: { "label": "cambie tarjeta de crédito", "for": ["not-cancelling", "has-payment-method"], "optionKey": "change-payment-method" },
                    cancel: { "label": "cancele suscripción pendiente", "for": ["not-cancelling", "has-payment-method"], "optionKey": "cancel-subscription-after-trial" },
                }                
            }
        },
        tou: {
            agreement: "Usted acuerda con nuestras condiciones del uso"
        },
        coupons: {
            checkNotice: "Examinando este código...",
            validNotice: "El código es válido. Click 'Próximo: Pago' para aplicar el código.",
            invalidNotice: "Este código no es válido.",
            expiredNotice: "Ya este código ha expirado.",
            consumedNotice: "Ya este código ha sido consumido."
        },        
        notices: {
            generalError: "Perdónenos. Algo hizo mal. Por favor intente otra vez.",
            badCurrent: "su contraseña no es correcta. por favor intente otra vez.",
            badMatch: "Contraseñas no son iguales.",
            badEmail: "Por favor entre un email válido.",
            badPassword: "Contraseñas deben tener por lo menos 1 letra mayuscula y 1 número, y ser entre 8 y 16 letras.",
            badEmailAndPassword: "por favor entre un email válido y una contraseña con por lo menos 1 letra mayuscula y 1 número, y ser entre 8 y 16 letras.",
            badCardDetails: "por favor entre los detalles completos de su tarjeta de crédito.",
            goodUpdatePymtMethod: "guardamos los detalles de su tarjeta de crédito.",
            goodUpgrade: "su cambio fue exitosa.",
            goodSignup: "Su inscripción fue exitosa.",
            goodCancel: "su solicitud de cancelación fue procesada.",
            goodReactivate: "el reinicio de su suscripción fue procesado.",
            emailExists: "Este email ya tiene una cuenta."
        },
        login: {
            enterCredsPanel: {
                title: "entrar",
            }
        },
        signup: {
            createUserPanel: {
                title: "Inscribirse",
                subTitle: ""
            },
            choosePlanPanel: {
                title: "Eliga un plan",
                subTitle: "Empiece gratis con básica o suscribirse a Pro."
            },            
            verifyEmailPanel: {
                title: "Verifque su email",
                subTitle: "Enviamos un mensaje a su email. Abra el email y click el enlace para verificar su cuenta."
            },
            paymentDetailsPanel: {
                title: "Caja",
                subTitle: "Cantidad para cargar:",
                defaultCurrency: "$",
                period: "/mes"
            },
            paymentSuccessPanel: {
                title: "Felicidades!",
                subTitle: "Su suscripción fue procesada."
            },
            trialSuccessPanel: {
                title: "Felicidades!",
                subTitle: "Su prueba gratis de $1-día es activada. La prueba gratis finilizará $2."
            }
        },
        resetPassword: {
            newPasswordPanel: {
                title: "restablecer contraseña",
            },
            successPanel: {
                title: "exito",
                subTitle: "cambiamos su contraseña."
            }
        },
        forgotPassword: {
            enterEmailPanel: {
                title: "obtenga un email para restablecer su contraseña",
            },
            successPanel: {
                title: "email de restablecer su contraseña enviado",
                subTitle: "enviamos un mensaje a su email. click el enlace para restablecer su contraseña."
            }            
        },
        verifyEmail: {
            applyPanel: {
                title: "un momento",
                subTitle: "espere por favor mientras verificamos su email."
            },
            successPanel: {
                title: "exito",
                subTitle: "su email es verificado."
            }
        },
        settings: {
            profile: {
                title: "perfil",
                labels: {
                    username: "nombre de usuario",
                    password: "contraseña"                    
                }
            },
            plan: {
                title: "plan"
            }
        },
        updatePayment: {
            cardDetailsPanel: {
                titleUpdate: "cambie tarjeta de crédito",
                titleAdd: "añade tarjeta de crédito",
                titleUpgrade: "cambie a Pro",
                subTitle: "por favor entre su tarjeta de crédito debajo."
            }
        }
    },
    modalContent: {
        buttons: {
            close: "Cerrar",
            home: "Regrese al Inicio"
        },
        confirmation: {
            title: "¿Está seguro?",
            planCancel: "Si cancela, perdirá su acceso a las características prémium incluyendo los datos completos de Form 4 y informes historicos."
        },
        displayArticles: {
            verifyEmail: "Por favor verifique su email para ver artículos.",
            login: "Por favor entre su cuenta o inscribirse para ver artículos."
        },
        settings: {
            login: "por favor entre su cuenta o inscribirse para ver artículos."
        },
        form4: {
            desktopTitle: "Form 4 archivado",
            mobileTitle: "datos seleccionados de este Form 4 archivado",
            accNum: "número de accesión",
            box1: {
                name: "persona"
            },
            box2: {
                issuer: "emisor",
                ticker: "símbolo"
            },
            box5: {
                relationship: "relación",
                director: "director",
                officer: "oficial",
                tenPctOwner: "dueño de 10%",
                other: "otro"
            },
            box6: {
                filingType: "tipo de Form 4 archivado",
                individual: "individuo",
                joint: "grupo"
            },
            footnotes: "notas a pie de Form 4 archivado"
        },
        tou: {
            title: "Condiciones del Uso"
        }
    },
    support: {
        questions: {
            "label": "Dudas? Envíenos un email:",
            "email": "support@inveyo.com"
        },
        enterprisePricingRequest: {
            "label": "¿Necesita un precio empresarial? Envíenos un email:",
            "email": "sales@inveyo.com"
        }
    }
}

export default strings