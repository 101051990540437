import { Alert } from "react-bootstrap";
// import { StringUtils } from "../scripts/utils";
import styles from "./notification.module.css";

const ids = {
    "area": { "id": "notificationArea" }
}

const Notification = ({ notification, setNotification, strings }) => {
    const notificationCloseClickHandler = (e) => {
        e.stopPropagation();
        setNotification(null);
    }

    const setNotificationString = (notification) => {
        /**
         * The `setNotificationString` method parses the notification object and returns the notification
         * string.
         * 
         * @param {object} notification
         * @return {string} notificationString
         * @catch {Error} err
         */
        try {
            let notificationString = strings.notifications[notification["category"]][notification["notice"]];
            if (0 < notification["options"].length) {
                for (let n = 0; n < notification["options"].length; n++) {
                    let replaceText = "$" + (n + 1);
                    let optionText = notification["options"][n]["option"];
                    let optionTextStyle = notification["options"][n]["textStyle"];
                    
                    if ("uppercase" === optionTextStyle) {
                        optionText = optionText.toUpperCase();
                    }
                    if ("lowercase" === optionTextStyle) {
                        optionText = optionText.toLowerCase();
                    }
                    if ("capitalize" === optionTextStyle) {
                        optionText = optionText[0].toUpperCase() + optionText.slice(1, );
                    }
                    
                    notificationString = notificationString.replace(replaceText, optionText);
                }
            }
            return notificationString
        } catch (err) {
            console.error(err);
            return null
        }
    }

    if (notification) {
        let notificationString = setNotificationString(notification);
        return(
            <div id = { ids.area.id } className = "container-fluid">
                <div className = "row">
                    <Alert className = "col-md-12 mb-0" variant = "primary">
                        <div className = "row">
                            <div className = "col-1"></div>
                            <div className = "col-10 text-center">{ notificationString }</div>
                            <div className = "col-1 text-end"><span className = { styles.close } onClick = {(e) => { notificationCloseClickHandler(e) }}>&#x2716;</span></div>
                        </div>
                    </Alert>
                </div>
            </div>
        )
    } else {
        return(
            <></>
        )
    }
}

export default Notification