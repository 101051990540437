import styles from "./table.module.css";

const ReportTableDataWrapper = ({ rowData, rowIndex, isMobile, report, handlers }) => {
    let _data = rowData;
    _data["issuer"] = _data["issuer"].replace(/\s\(\s\)\n\s\s:/g, "") // Fix to replace erroneous character sequence captured when scraping issuer name

    if (isMobile) {
        return(
            <tr className = { (report.indexOf("acq") > -1) ? styles.acqNonDeriv : styles.disNonDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"], _data["issuer"]) }>
                <td>{_data["acceptanceTime"]}</td>
                <td>{_data["issuerTicker"]}</td>
                <td>{_data["issuer"]}</td>
                <td>{_data["totalValue"]}</td>
            </tr>            
        )
    } else {
        return(
            <tr className = { (report.indexOf("acq") > -1) ? styles.acqNonDeriv : styles.disNonDeriv } onClick = { (e) => handlers.click(e, _data["accessionNum"], _data["issuerTicker"], _data["issuer"]) }>
                <td>{_data["acceptanceDate"]}</td>
                <td>{_data["acceptanceTime"]}</td>
                <td>{_data["issuerTicker"]}</td>
                <td>{_data["issuer"]}</td>
                <td>{_data["industry"]}</td>
                <td>{_data["reportingPerson"]}</td>
                <td>{_data["reportingPersonTitle"]}</td>
                <td>{_data["price"]}</td>
                <td>{_data["amount"]}</td>
                <td>{_data["totalValue"]}</td>
                <td>{_data["amountOwned"]}</td>
                <td>{_data["delta"]}</td>
            </tr>
        )
    }
}

export default ReportTableDataWrapper