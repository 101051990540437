import { Fragment } from "react";
import LogoAreaNavOptionWrapper from "./logo-area-nav-option-wrapper";
// import logo from "../img/logo.png";
import styles from "./logo-area.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ids = {
    "area": { "id": "logoArea" }
}

const LogoArea = ({ handlers, user, strings, isMobile, LANGS, setIsMetadataInitialized }) => {
    const logoAreaNavProps = strings.logoAreaNav;
    const logoAreaBrandingName = strings.logoAreaBranding["name"];
    const logoAreaBrandingShortName = strings.logoAreaBranding["shortName"];
    // const logoAreaBrandingVersion = strings.logoAreaBranding["version"];
    const logoAreaBrandingVersion = "";
    const logoAreaBrandingTagline = strings.logoAreaBranding["tagline"];

    return(
        <div id = { ids.area.id } className = { [styles.logoAreaContainer, "container-fluid"].join(" ")}>
            <div className = "row align-items-center">
                <div className = "col">
                    <ul className = "nav align-items-center">
                        {
                            Object.keys(logoAreaNavProps).map((_key) => {
                                return(
                                    <LogoAreaNavOptionWrapper
                                        key = { logoAreaNavProps[_key]["label"] }
                                        handlers = { handlers }
                                        label = { logoAreaNavProps[_key]["label"] }
                                        icon = { logoAreaNavProps[_key]["icon"] }
                                        navKey = { logoAreaNavProps[_key]["navKey"] }
                                        LANGS = { LANGS }>
                                    </LogoAreaNavOptionWrapper>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className = { [styles.logo, "col"].join(" ") } onClick = {(e) => { handlers.logo.click(e) }}>
                    <div className = { styles.brandingContainer }>
                        <div className = { [styles.primaryBranding, "text-center", "pb-1"].join(" ") }>
                            <div className = { ["d-flex", "justify-content-center", "align-items-center"].join(" ") }>
                                <svg fill = "none" viewBox = "0 0 158 216" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask1_1_3" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
                                        <path d="M3.21979 45.8854L157.185 0.00665283C157.185 0.00665283 111.379 34.268 93.3421 96.632C75.3057 158.997 93.0828 215.664 93.0828 215.664C93.0828 215.664 -16.1391 168.108 3.21979 45.8854Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask1_1_3)">
                                        <path d="M3.21979 45.8854L157.185 0.00665283C157.185 0.00665283 111.379 34.268 93.3421 96.632C75.3057 158.997 93.0828 215.664 93.0828 215.664C93.0828 215.664 -16.1391 168.108 3.21979 45.8854Z" fill="url(#paint1_linear_1_3)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint1_linear_1_3" x1="79.5576" y1="154.184" x2="78.9473" y2="12.904" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#7FD6F7"/>
                                            <stop offset="0.00716396" stopColor="#7FD6F7"/>
                                            <stop offset="0.667529" stopColor="#346DBF"/>
                                            <stop offset="1" stopColor="#346DBF"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <span className = "fs-4 text-capitalize">{ (isMobile) ? logoAreaBrandingShortName : logoAreaBrandingName }</span>
                                <span className = "d-inline-block ms-2"><small>{`${logoAreaBrandingVersion}`}</small></span>
                            </div>
                        </div>
                        <div className = { [styles.secondaryBranding, "text-center"].join(" ") }>
                            <span className = { ["fs-5", "text-capitalize"].join(" ") }>{ logoAreaBrandingTagline }</span>
                        </div>
                    </div>
                </div>
                <div className = "col">
                    <div className = "text-end">
                        {
                            (null === user) &&
                            <Fragment>
                                <button type = "button" className = "btn btn-outline-light me-2 text-capitalize" onClick = { (e) => handlers.login.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav1.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav1.label }</span> }</button>
                                <button type = "button" className = "btn btn-warning text-capitalize" onClick = { (e) => handlers.signup.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav2.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav2.label }</span> }</button>
                            </Fragment>                            
                        }
                        {
                            (null !== user && user.emailVerified) &&
                            <Fragment>
                                <button type = "button" className = "btn btn-outline-light me-2 text-capitalize" onClick = { (e) => handlers.logout.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav4.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav4.label }</span> }</button>
                                <button type = "button" className = "btn btn-warning text-capitalize" onClick = { (e) => handlers.settings.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav5.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav5.label }</span> }</button>
                            </Fragment>
                        }
                        {
                            (null !== user && !user.emailVerified) &&
                            <Fragment>
                                <button type = "button" className = "btn btn-outline-light me-2 text-capitalize" onClick = { (e) => handlers.verify.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav3.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav3.label }</span> }</button>
                                <button type = "button" className = "btn btn-outline-light me-2 text-capitalize" onClick = { (e) => handlers.logout.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav4.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav4.label }</span> }</button>
                                <button type = "button" className = "btn btn-warning text-capitalize" onClick = { (e) => handlers.settings.click(e) }><FontAwesomeIcon icon = { strings.logoAreaUser.nav5.icon }></FontAwesomeIcon>{ !isMobile && <span>&nbsp;{ strings.logoAreaUser.nav5.label }</span>}</button>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogoArea