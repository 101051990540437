import { Fragment, useState } from "react";
import EntityTableDataWrapper from "./table-entity-data-wrapper.jsx";
import Form4Modal from "./form4-filing-modal.jsx";
import { WebUtils } from "../scripts/utils.js";
import services from "../scripts/services.js";

const EntityTableRowWrapper = ({ rowData, rowIndex, isMobile, strings }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form4Data, setForm4Data] = useState(null);

    const toggleModal = () => {
        if (true === isModalVisible) {
            // Reset `form4Data` to `null`
            setForm4Data(null);
        }
        setIsModalVisible((state) => !state);
    }

    const entityTableRowClickHandler = (e, accessionNum, issuerTicker) => {
        let _command = {
            action: "get-form-4-data",
            accessionNum: accessionNum,
            ticker: issuerTicker
        }
        let _command_str = JSON.stringify(_command);
        WebUtils.request(services.webServices, _command_str)
        .then((response) => {
            // DEBUG
            console.log(response);
            // DEBUG
            
            let _data = JSON.parse(response);

            if ("error" in _data) {
                return false
            } else {
                // Append accession number to `_data`
                _data["accession_number"] = accessionNum;

                setForm4Data(_data);
                return true
            }
        })
        .then((isDataSet) => {
            if (isDataSet) {
                setIsModalVisible(true);
            }
        })
        .catch((err) => { console.error(err) })

        // if ((userContext.isPro) || (userContext.justUpgraded)) { // Display Form 4 details if pro user
        //     // Strip suffix from report accession number
        //     // let lastHyphenIndex = accessionNum.lastIndexOf("-");
        //     // accessionNum = accessionNum.slice(0, lastHyphenIndex);

        //     let _command = {
        //         action: "get-form-4-data",
        //         accessionNum: accessionNum,
        //         ticker: issuerTicker
        //     }
        //     let _command_str = JSON.stringify(_command);
        //     WebUtils.request(services.webServices, _command_str)
        //     .then((response) => {
        //         // DEBUG
        //         console.log(response);
        //         // DEBUG
                
        //         let _data = JSON.parse(response);

        //         if ("error" in _data) {
        //             return false
        //         } else {
        //             // Append accession number to `_data`
        //             _data["accession_number"] = accessionNum;

        //             setForm4Data(_data);
        //             return true
        //         }
        //     })
        //     .then((isDataSet) => {
        //         if (isDataSet) {
        //             setIsModalVisible(true);
        //         }
        //     })
        //     .catch((err) => { console.error(err) })
        // }
    }

    return(
        <Fragment>
            <EntityTableDataWrapper
                rowData = { rowData }
                rowIndex = { rowIndex }
                isMobile = { isMobile }
                handlers = {{ click: entityTableRowClickHandler }}>
            </EntityTableDataWrapper>
            {
                (null !== form4Data) && <Form4Modal show = { isModalVisible } handlers = {{ toggle: toggleModal }} form4Data = { form4Data } strings = { strings } isMobile = { isMobile }></Form4Modal>
            }            
        </Fragment>    
    )
}

export default EntityTableRowWrapper