import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert } from "react-bootstrap";
import { useState } from "react";
import { WebUtils } from "../scripts/utils";
import services from "../scripts/services";

const CheckoutForm = ({ clientSecret, custId, strings, amount, setClientSecret, setConfirmPayment, transitionToPaymentSuccessPanel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [statusMessage, setStatusMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const strContent = {
    pymtDtlsPanelTitle: strings.formContent.signup.paymentDetailsPanel.title,
    pymtDtlsPanelSubTitle: strings.formContent.signup.paymentDetailsPanel.subTitle,
    pymtDtlsPanelCurr: strings.formContent.signup.paymentDetailsPanel.defaultCurrency,
    pymtDtlsPanelPeriod: strings.formContent.signup.paymentDetailsPanel.period,
    goodSignup: strings.formContent.notices.goodSignup,
    genError: strings.formContent.notices.generalError,
    submitPayNowLbl: strings.formContent.buttons.submitPayNow,
    submitProcLbl: strings.formContent.buttons.submitProcessing
  }

  const formatPrice = (amount) => {
    let _price = parseInt(amount)/100;
    return strContent.pymtDtlsPanelCurr + _price.toString()
  }

  const processCheckout = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded
      // Disable form submission until Stripe.js has loaded
      return
    }

    setIsProcessing(true);
    let result = null;
    let cardElement = elements.getElement('card');

    result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    
    if ("error" in result) {
      setStatusMessage(result.error.message);
    } else {
      if ("succeeded" === result.paymentIntent.status) {
          let _command_str = '{"action": "modify-stripe-customer", "modifyAction": "set-default-payment-method", "id": "' + custId + '", "paymentMethod": "' + result.paymentIntent.payment_method + '"}';
          let _set_as_default_response = await WebUtils.request(services["webServices"], _command_str);
          let _default_pm_value = JSON.parse(_set_as_default_response)["defaultPaymentMethod"];
          if (result.paymentIntent.payment_method === _default_pm_value) {
              // setStatusMessage(strContent.goodSignup);
              await setClientSecret("");
              await setConfirmPayment(true);
              transitionToPaymentSuccessPanel();
          }
      } else {
          setStatusMessage(strContent.genError);
      }
    }
    setIsProcessing(false);
  }

  return (
    <form className = "d-inline-block mt-3">
      <h3 className = "mb-2">{ strContent.pymtDtlsPanelTitle }</h3>
      <div className = "fs-5 mb-1">{`${strContent.pymtDtlsPanelSubTitle} ${formatPrice(amount)}${strContent.pymtDtlsPanelPeriod}` }</div>
      { statusMessage && <Alert variant = "info" className = "mb-3">{ statusMessage }</Alert> }
      <CardElement />
      <div className = "d-grid gap-2 mt-3">
          <button type = "submit" className = "btn btn-secondary pt-3 pb-3 fs-5" disabled = { isProcessing || !stripe || !elements } onClick = { (e) => processCheckout(e) }>{ isProcessing ? strContent.submitProcLbl : strContent.submitPayNowLbl }</button>
      </div>
    </form>
  )
}

export default CheckoutForm