import { Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { StringUtils } from "../scripts/utils";
import urlData from "../scripts/urls.js";
import styles from "./form4-filing-modal.module.css";

const Form4Modal = ({ show, handlers, form4Data, strings, isMobile }) => {
    const strContent = {
        desktopTitle: StringUtils.capitalizeWords(strings.modalContent.form4.desktopTitle),
        mobileTitle: StringUtils.capitalizeWords(strings.modalContent.form4.mobileTitle),
        accNum: StringUtils.capitalizeWords(strings.modalContent.form4.accNum),
        name: StringUtils.capitalizeWords(strings.modalContent.form4.box1.name),
        issuer: StringUtils.capitalizeWords(strings.modalContent.form4.box2.issuer),
        ticker: StringUtils.capitalizeWords(strings.modalContent.form4.box2.ticker),
        relationship: StringUtils.capitalizeWords(strings.modalContent.form4.box5.relationship),
        director: StringUtils.capitalizeWords(strings.modalContent.form4.box5.director),
        officer: StringUtils.capitalizeWords(strings.modalContent.form4.box5.officer),
        tenPctOwner: StringUtils.capitalizeWords(strings.modalContent.form4.box5.tenPctOwner),
        other: StringUtils.capitalizeWords(strings.modalContent.form4.box5.other),
        filingType: StringUtils.capitalizeWords(strings.modalContent.form4.box6.filingType),
        individual: StringUtils.capitalizeWords(strings.modalContent.form4.box6.individual),
        joint: StringUtils.capitalizeWords(strings.modalContent.form4.box6.joint),
        footnotes: StringUtils.capitalizeWords(strings.modalContent.form4.footnotes),
        close: StringUtils.capitalizeWords(strings.modalContent.buttons.close)
    }

    if (null !== form4Data && !isMobile) {
        return (
            <Modal id = "form4Modal" show = { show } onHide = { handlers.toggle }>
                <Modal.Header closeButton>
                    <Modal.Title>{ strContent.desktopTitle }</Modal.Title>                   
                </Modal.Header>
                <Modal.Body>
                    <div className = "fw-semibold">
                        <span>{ strContent.accNum }</span>
                    </div>
                    <div>
                        <span>{ form4Data.accession_number }</span>
                    </div>
                    <hr></hr>                               
                    <table width="100%" cellPadding="4">
                        <tbody>
                            <tr>
                                <td width="20%" colSpan="2" valign="top" align="center" className={styles.formName}>
                                    FORM 4
                                </td>
                                <td rowSpan="2" width="60%" valign="top" align="center">
                                    <span className={styles.formTitle}>
                                        UNITED STATES SECURITIES AND EXCHANGE COMMISSION
                                    </span>
                                    <br></br>
                                    <span className={styles.medSmallFormText}>
                                        Washington, D.C. 20549
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span className={styles.formTitle}>
                                        STATEMENT OF CHANGES IN BENEFICIAL OWNERSHIP
                                    </span>
                                    <br></br>
                                    <span className={["text-center", styles.medSmallFormText].join(" ")}>
                                        Filed pursuant to Section 16(a) of the Securities Exchange Act of 1934
                                        <br></br>
                                        or Section 30(h) of the Investment Company Act of 1940
                                    </span>
                                </td>
                                <td rowSpan="2" width="20%" valign="top" align="center">
                                    <table width="100%" border="1" summary="OMB Approval Status Box">
                                        <tbody>
                                            <tr>
                                                <td className={styles.formTextC}>OMB APPROVAL</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" summary="OMB Interior Box">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{"paddingTop": "0", "paddingBottom": "0"}} className={styles.smallFormText} colSpan="3">OMB Number:</td>
                                                                <td style={{"paddingTop": "0", "paddingBottom": "0"}} className={styles.smallFormTextR}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{"paddingTop": "0", "paddingBottom": "0"}} className={styles.smallFormText} colSpan="4">Estimated average burden</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{"paddingTop": "0", "paddingBottom": "0"}} className={styles.smallFormText} colSpan="3">hours per response:</td>
                                                                <td style={{"paddingTop": "0", "paddingBottom": "0"}} className={styles.smallFormTextR}></td>
                                                            </tr>                        
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>  
                            </tr>
                            <tr valign="middle">
                                <td>
                                    <table width="100%" border="1" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>&nbsp;&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className={styles.smallFormText}>
                                    Check this box if no longer subject to Section 16. Form 4 or Form 5 obligations may continue.<i>See</i>Instruction 1(b).
                                </td>
                            </tr>
                            <tr valign="middle">
                                <td>
                                    <table width="100%" border="1" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>&nbsp;&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className={styles.smallFormText}>
                                    Check this box to indicate that a transaction was made pursuant to a contract, instruction or written plan for the purchase or sale of equity securities of the issuer that is intended to satisfy the affirmative defense conditions of Rule 10b5-1(c). See Instruction 10.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="1" cellSpacing="0" cellPadding="4">
                        <tbody>
                            <tr>
                                <td className = {styles.box1} rowSpan="4" width="35%" valign="top">
                                    <span className={styles.medSmallFormText}>1. Name and Address of Reporting Person<sup>*</sup></span>
                                    <table border="0" width="100%">
                                        <tbody>                                        
                                            <tr>
                                                <td className={styles.formDataMed}>
                                                    <a rel="noreferrer" target="_blank" href={ urlData.edgar_prefix + form4Data.box1.reporting_person_link }>{ form4Data.box1.reporting_person }</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr width="98%"></hr>
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="33%" className={styles.medSmallFormText}>(Last)</td>
                                                <td width="33%" className={styles.medSmallFormText}>(First)</td>
                                                <td width="33%" className={styles.medSmallFormText}>(Middle)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className={styles.formDataMed}>{form4Data.box1.reporting_person_street_address}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr width="98%"></hr>
                                    <span className={styles.medSmallFormText}>(Street)</span>                                   
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="33%"><span className={styles.formDataMed}>{ form4Data.box1.reporting_person_city_state_zip.split(" ")[0] }</span></td>
                                                <td width="33%"><span className={styles.formDataMed}>{ form4Data.box1.reporting_person_city_state_zip.split(" ")[1] }</span></td>
                                                <td width="33%"><span className={styles.formDataMed}>{ form4Data.box1.reporting_person_city_state_zip.split(" ")[2] }</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr width="98%"></hr>
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="33%" className={styles.medSmallFormText}>(City)</td>
                                                <td width="33%" className={styles.medSmallFormText}>(State)</td>
                                                <td width="33%" className={styles.medSmallFormText}>(Zip)</td>
                                            </tr>
                                        </tbody>
                                    </table>                                     
                                </td>
                                <td className = {styles.box2} width="35%" valign="top">
                                    <span className={styles.medSmallFormText}>2. Issuer Name <b>and</b> Ticker or Trading Symbol</span>
                                    <br></br>
                                    <a className={styles.formDataMed} rel="noreferrer" target="_blank" href={ urlData.edgar_prefix + form4Data.box2.issuer_link }>{ form4Data.box2.issuer_name }</a>
                                    [ <span className={styles.formDataMed}>{ form4Data.box2.issuer_ticker }</span> ]
                                </td>
                                <td className = {styles.box5} rowSpan="2" valign="top">
                                    <span className={ styles.medSmallFormText }>
                                        5. Relationship of Reporting Person(s) to Issuer
                                        <br></br>
                                        (Check all applicable)
                                    </span>
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="15%" align="center"><span className={styles.formData}>{("X" === form4Data.box5.relationship_director) && "X"}</span></td>
                                                <td width="35%" className={styles.medSmallFormText}>Director</td>
                                                <td width="15%" align="center"><span className={styles.formData}>{("X" === form4Data.box5.relationship_tenPctOwner) && "X"}</span></td>
                                                <td width="35%" className={styles.medSmallFormText}>10% Owner</td>
                                            </tr>
                                            <tr>
                                                <td align="center"><span className={styles.formData}>{("X" === form4Data.box5.relationship_officer) && "X"}</span></td>
                                                <td className={styles.medSmallFormText}>Officer (give title below)</td>
                                                <td align="center"><span className={styles.formData}>{("X" === form4Data.box5.relationship_other) && "X"}</span></td>
                                                <td className={styles.medSmallFormText}>Other (specify below)</td>
                                            </tr>
                                            <tr>
                                                <td width="15%" align="center"></td>
                                                <td width="35%" align="left" className={styles.formDataMed}>{("N/A" === form4Data.box5.relationship_title) ? "" : form4Data.box5.relationship_title}</td>
                                                <td width="15%" align="center"></td>
                                                <td width="35%" align="left" className={styles.formDataMed}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className = {styles.box3} valign="top">
                                    <span className={styles.medSmallFormText}>3. Date of Earliest Transaction (Month/Day/Year)</span>
                                    <br></br>
                                    <span className={styles.formDataMed}>{ form4Data.box3.earliest_transaction_date }</span>
                                </td>
                            </tr>
                            <tr>
                                <td className = {styles.box4} valign="top">
                                    <span className={styles.medSmallFormText}>4. If Amendment, Date of Original Filed (Month/Day/Year)</span>
                                    <br></br>
                                    <span className={styles.formDataMed}>{("N/A" === form4Data.box4.original_date_if_ammendment) ? "" : form4Data.box4.original_date_if_ammendment}</span>
                                </td>
                                <td valign="top">
                                    <span className={styles.medSmallFormText}>6. Individual or Joint/Group Filing (Check Applicable Line)</span>
                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="15%" align="center">
                                                    <span className={styles.formData}>{("X" === form4Data.box6.individual_filing) && "X"}</span>
                                                </td>
                                                <td width="85%" className={styles.medSmallFormText}>Form filed by One Reporting Person</td>
                                            </tr>
                                            <tr>
                                                <td width="15%" align="center">
                                                    <span className={styles.formData}>{("X" === form4Data.box6.joint_filing) && "X"}</span>
                                                </td>
                                                <td width="85%" className={styles.medSmallFormText}>Form filed by More than One Reporting Person</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="1" cellSpacing="0" cellPadding="4">
                        <thead className={styles.tableHeader}>
                            <tr>
                                <th width="100%" valign="top" colSpan="11" align="center" className={[styles.formTextC, styles.tableHeaderTitle].join(" ")}>
                                    <b>Table I - Non-Derivative Securities Acquired, Disposed of, or Beneficially Owned</b>
                                </th>
                            </tr>
                            <tr>
                                <th width="36%" valign="top" rowSpan="2" align="left" className={[styles.medSmallFormText, styles.tableHeaderCell].join(" ")}>
                                    1. Title of Security (Instr. 3)
                                </th>
                                <th width="6%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    2. Transaction Date (Month/Day/Year)
                                </th>
                                <th width="7%" valign="top" colSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table1Box3].join(" ")}>
                                    3. Transaction Code (Instr. 8)
                                </th>
                                <th width="5%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    3A. Deemed Execution Date, if any (Month/Day/Year)
                                </th>                                
                                <th width="19%" valign="top" colSpan="3" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table1Box4].join(" ")}>
                                    4. Securities Acquired (A) or Disposed Of (D) (Instr. 3, 4 and 5)
                                </th>
                                <th width="11%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    5. Amount of Securities Beneficially Owned Following Reported Transaction(s) (Instr. 3 and 4)
                                </th>
                                <th width="9%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    6. Ownership Form: Direct (D) or Indirect (I) (Instr. 4)
                                </th>
                                <th width="8%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    7. Nature of Indirect Beneficial Ownership (Instr. 4)
                                </th>
                            </tr>
                            <tr>
                                <th width="4%" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Code</th>
                                <th width="3%" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>V</th>
                                <th width="8%" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Amount</th>
                                <th width="5%" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>(A) or (D)</th>
                                <th width="6%" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (Object.keys(form4Data.table1).map((key, i) => {
                                    return(
                                        <tr key={`form-4-table-1-row-${i}`}>
                                            <td align="left">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_security_title"]) ? "" : form4Data.table1[key]["non_deriv_security_title"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_security_title_footnote"]) ? "" : form4Data.table1[key]["non_deriv_security_title_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_transaction_date"]) ? "" : form4Data.table1[key]["non_deriv_transaction_date"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_transaction_date_footnote"]) ? "" : form4Data.table1[key]["non_deriv_transaction_date_footnote"]}</sup></span>
                                            </td>
                                            {
                                                /**
                                                 * Note that the `non_deriv_transaction_code_footnotes` with a `s` key is correct.
                                                 */
                                            }
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_transaction_code"]) ? "" : form4Data.table1[key]["non_deriv_transaction_code"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_transaction_code_footnotes"]) ? "" : form4Data.table1[key]["non_deriv_transaction_code_footnotes"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_voluntary_reporting"]) ? "" : form4Data.table1[key]["non_deriv_voluntary_reporting"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_voluntary_reporting_footnote"]) ? "" : form4Data.table1[key]["non_deriv_voluntary_reporting_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_amount"]) ? "" : form4Data.table1[key]["non_deriv_amount"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_amount_footnote"]) ? "" : form4Data.table1[key]["non_deriv_amount_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_amount"]) ? "" : form4Data.table1[key]["non_deriv_amount"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_amount_footnote"]) ? "" : form4Data.table1[key]["non_deriv_amount_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_acq_or_dis"]) ? "" : form4Data.table1[key]["non_deriv_acq_or_dis"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_acq_or_dis_footnote"]) ? "" : form4Data.table1[key]["non_deriv_acq_or_dis_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_currency"]) ? "" : form4Data.table1[key]["non_deriv_currency"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_currency_footnote"]) ? "" : form4Data.table1[key]["non_deriv_currency_footnote"]}</sup></span>
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_price"]) ? "" : form4Data.table1[key]["non_deriv_price"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_price_footnote"]) ? "" : form4Data.table1[key]["non_deriv_price_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_amount_beneficially_owned"]) ? "" : form4Data.table1[key]["non_deriv_amount_beneficially_owned"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_amount_beneficially_owned_footnote"]) ? "" : form4Data.table1[key]["non_deriv_amount_beneficially_owned_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_ownership_form"]) ? "" : form4Data.table1[key]["non_deriv_ownership_form"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_ownership_form_footnote"]) ? "" : form4Data.table1[key]["non_deriv_ownership_form_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table1[key]["non_deriv_nature_of_indirect_ownership"]) ? "" : form4Data.table1[key]["non_deriv_nature_of_indirect_ownership"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table1[key]["non_deriv_nature_of_indirect_ownership_footnote"]) ? "" : form4Data.table1[key]["non_deriv_nature_of_indirect_ownership_footnote"]}</sup></span>
                                            </td>
                                        </tr>
                                    )
                                })
                            )}                            
                        </tbody>
                    </table>
                    <table width="100%" border="1" cellSpacing="0" cellPadding="4">
                        <thead className = {styles.tableHeader}>
                            <tr>
                                <th width="100%" valign="top" colSpan="16" align="center" className={[styles.formTextC, styles.tableHeaderTitle].join(" ")}>
                                    <b>Table II - Derivative Securities Acquired, Disposed of, or Beneficially Owned</b>
                                    <br></br>
                                    <b>(e.g., puts, calls, warrants, options, convertible securities)</b>
                                </th>
                            </tr>
                            <tr>
                                <th width="13%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    1. Title of Derivative Security (Instr. 3)
                                </th>
                                <th width="5%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    2. Conversion or Exercise Price of Derivative Security
                                </th>
                                <th width="5%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    3. Transaction Date (Month/Day/Year)
                                </th>
                                <th width="5%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    3A. Deemed Execution Date, if any (Month/Day/Year)
                                </th>
                                <th width="9%" valign="top" colSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table2Box4].join(" ")}>
                                    4. Transaction Code (Instr. 8)
                                </th>
                                <th width="10%" valign="top" colSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table2Box5].join(" ")}>
                                    5. Number of Derivative Securities Acquired (A) or Disposed of (D) (Instr. 3, 4 and 5)
                                </th>
                                <th width="9%" valign="top" colSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table2Box6].join(" ")}>
                                    6. Date Exercisable and Expiration Date (Month/Day/Year)
                                </th>
                                <th width="17%" valign="top" colSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell, styles.table2Box7].join(" ")}>
                                    7. Title and Amount of Securities Underlying Derivative Security (Instr. 3 and 4)
                                </th>
                                <th width="6%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    8. Price of Derivative Security (Instr. 5)
                                </th>
                                <th width="6%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    9. Number of derivative Securities Beneficially Owned Following Reported Transaction(s) (Instr. 4)
                                </th>
                                <th width="6%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    10. Ownership Form: Direct (D) or Indirect (I) (Instr. 4)
                                </th>
                                <th width="7%" valign="top" rowSpan="2" align="left" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>
                                    11. Nature of Indirect Beneficial Ownership (Instr. 4)
                                </th>
                            </tr>                            
                            <tr>
                                <th width="4%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Code</th>
                                <th width="4%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>V</th>
                                <th width="5%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>(A)</th>
                                <th width="5%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>(D)</th>
                                <th width="5%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Date Exercisable</th>
                                <th width="4%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Expiration Date</th>
                                <th width="10%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Title</th>
                                <th width="7%" valign="bottom" align="center" className={[styles.smallFormText, styles.tableHeaderCell].join(" ")}>Amount or Number of Shares</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (Object.keys(form4Data.table2).map((key, i) => {
                                    return(
                                        <tr key={`form-4-table-2-row-${i}`}>
                                            <td align="left">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_security_title"]) ? "" : form4Data.table2[key]["deriv_security_title"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_security_title_footnote"]) ? "" : form4Data.table2[key]["deriv_security_title_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormText}>{("N/A" === form4Data.table2[key]["deriv_conversion_price_currency"]) ? "" : form4Data.table2[key]["deriv_conversion_price_currency"]}</span>
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_conversion_price_value"]) ? "" : form4Data.table2[key]["deriv_conversion_price_value"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_conversion_price_footnote"]) ? "" : form4Data.table2[key]["deriv_conversion_price_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_transaction_date"]) ? "" : form4Data.table2[key]["deriv_transaction_date"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_transaction_date_footnote"]) ? "" : form4Data.table2[key]["deriv_transaction_date_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_deemed_execution_date"]) ? "" : form4Data.table2[key]["deriv_deemed_execution_date"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_deemed_execution_date_footnote"]) ? "" : form4Data.table2[key]["deriv_deemed_execution_date_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_transaction_code"]) ? "" : form4Data.table2[key]["deriv_transaction_code"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_transaction_code_footnote"]) ? "" : form4Data.table2[key]["deriv_transaction_code_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_voluntary_reporting"]) ? "" : form4Data.table2[key]["deriv_voluntary_reporting"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_voluntary_reporting_footnote"]) ? "" : form4Data.table2[key]["deriv_voluntary_reporting_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_amount_acq"]) ? "" : form4Data.table2[key]["deriv_amount_acq"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_amount_acq_footnote"]) ? "" : form4Data.table2[key]["deriv_amount_acq_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_amount_dis"]) ? "" : form4Data.table2[key]["deriv_amount_dis"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_amount_dis_footnote"]) ? "" : form4Data.table2[key]["deriv_amount_dis_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_date_exercisable"]) ? "" : form4Data.table2[key]["deriv_date_exercisable_dis"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_date_exercisable_footnote"]) ? "" : form4Data.table2[key]["deriv_date_exercisable_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_expiration_date"]) ? "" : form4Data.table2[key]["deriv_expiration_date"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_expiration_date_footnote"]) ? "" : form4Data.table2[key]["deriv_expiration_date_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_underlying_security_title"]) ? "" : form4Data.table2[key]["deriv_underlying_security_title"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_underlying_security_title_footnote"]) ? "" : form4Data.table2[key]["deriv_underlying_security_title_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_underlying_security_amount"]) ? "" : form4Data.table2[key]["deriv_underlying_security_amount"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_underlying_security_amount_footnote"]) ? "" : form4Data.table2[key]["deriv_underlying_security_amount_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_security_price_currency"]) ? "" : form4Data.table2[key]["deriv_security_price_currency"]}</span>
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_security_price_value"]) ? "" : form4Data.table2[key]["deriv_security_price_value"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_security_price_footnote"]) ? "" : form4Data.table2[key]["deriv_security_price_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_amount_beneficially_owned"]) ? "" : form4Data.table2[key]["deriv_amount_beneficially_owned"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_amount_beneficially_owned_footnote"]) ? "" : form4Data.table2[key]["deriv_amount_beneficially_owned_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_ownership_form"]) ? "" : form4Data.table2[key]["deriv_ownership_form"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_ownership_form_footnote"]) ? "" : form4Data.table2[key]["deriv_ownership_form_footnote"]}</sup></span>
                                            </td>
                                            <td align="center">
                                                <span className={styles.smallFormData}>{("N/A" === form4Data.table2[key]["deriv_nature_of_indirect_ownership"]) ? "" : form4Data.table2[key]["deriv_nature_of_indirect_ownership"]}</span>
                                                <span className={styles.footnoteData}><sup>{("N/A" === form4Data.table2[key]["deriv_nature_of_indirect_ownership_footnote"]) ? "" : form4Data.table2[key]["deriv_nature_of_indirect_ownership_footnote"]}</sup></span>
                                            </td>                                                                                                                 
                                        </tr>
                                    )
                                })
                            )}
                        </tbody>
                    </table>
                    <table border="0" width="100%">
                        <tbody>
                            <tr>
                                <td className={styles.medSmallFormText}>
                                    <b>Explanation of Responses:</b>
                                </td>
                            </tr>
                            {
                                Object.keys(form4Data.footnotes).map((key, i) => {
                                    return(
                                        <tr key = {`form-4-footnote-${i}`}>
                                            <td className={styles.footnoteData}>
                                                {form4Data.footnotes[key]}
                                            </td>
                                        </tr> 
                                    )
                                })
                            }
                            <br></br>
                            <tr>
                                <td className={styles.formText}>
                                    <b>Remarks:</b>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.footnoteData}></td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" border="0">
                        <tbody>
                            {(() => {
                                for (let s=0; s < form4Data.signers.length; s+=2) {
                                    return(
                                        <tr>
                                            <td width="60%"></td>
                                            <td width="20%">
                                                <u><span className={styles.formData}>{form4Data.signers[s]}</span></u>
                                            </td>
                                            <td width="20%">
                                                <u><span className={styles.formData}>{form4Data.signers[s+1]}</span></u>
                                            </td>
                                        </tr>                                        
                                    )
                                }
                            })()}
                            <tr>
                                <td></td>
                                <td className={styles.medSmallFormText}>** Signature of Reporting Person</td>
                            <   td className={styles.medSmallFormText}>Date</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className={styles.medSmallFormText}>
                                    Reminder: Report on a separate line for each class of securities beneficially owned directly or indirectly.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className={styles.medSmallFormText}>
                                    * If the form is filed by more than one reporting person, <i>see</i> Instruction 4(b)(v).
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className={styles.medSmallFormText}>
                                    ** Intentional misstatements or omissions of facts constitute Federal Criminal Violations <i>See</i> 18 U.S.C. 1001 and 15 U.S.C. 78ff(a).
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className={styles.medSmallFormText}>
                                    Note: File three copies of this Form, one of which must be manually signed. If space is insufficient, <i>see</i> Instruction 6 for procedure.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3" className={styles.medSmallFormText}>
                                    <b>Persons who respond to the collection of information contained in this form are not required to respond unless the form displays a currently valid OMB Number.</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "outline-secondary" onClick = { handlers.toggle }>{ strContent.close }</Button>
                </Modal.Footer>
            </Modal>
        )
    } 

    if (null !== form4Data && isMobile) {
        return(
            <Modal id = "form4Modal" show = { show } onHide = { handlers.toggle }>
                <Modal.Header closeButton>
                    <Modal.Title>{ strContent.mobileTitle }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className = "fw-semibold">
                        <span>{ strContent.accNum }</span>
                    </div>
                    <div>
                        <span>{ form4Data.accession_number }</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.name }</span>
                    </div>
                    <div>
                        <span>{`${form4Data.box1.reporting_person} (${form4Data.box5.relationship_title})`}</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.relationship }</span>
                    </div>
                    <div>
                        {
                            ("X" === form4Data.box5.relationship_director) && <div>{ strContent.director }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_officer) && <div>{ strContent.officer }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_tenPctOwner) && <div>{ strContent.tenPctOwner }</div>
                        }
                        {
                            ("X" === form4Data.box5.relationship_other) && <div>{ strContent.other }</div>
                        }                        
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.issuer }</span>
                    </div>
                    <div>
                        <span>{`${form4Data.box2.issuer_name} (${form4Data.box2.issuer_ticker})`}</span>
                    </div>
                    <div className = "fw-semibold">
                        <span>{ strContent.filingType }</span>
                    </div>
                    <div>
                        {
                            ("X" === form4Data.box6.individual_filing) ? (
                                <span>{ strContent.individual }</span>
                            ) : (
                                <span>{ strContent.joint} </span>
                            )
                        }
                    </div>
                    {
                        (form4Data.footnotes.length > 0) && 
                            <Fragment>
                                <div className = "fw-semibold">
                                    { strContent.footnotes }
                                </div>
                                <div>
                                    { form4Data.footnotes.map((footnote) => { return (<div>{ footnote }</div>) }) }
                                </div>
                            </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "outline-secondary" onClick = { handlers.toggle }>{ strContent.close }</Button>
                </Modal.Footer>
            </Modal>         
        )
    }
}

export default Form4Modal