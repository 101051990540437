import { Fragment } from "react";
import { WebUtils } from "../scripts/utils";
import services from "../scripts/services";

const ids = {
    "coupon": { "id": "signupCoupon" }
}

const Coupon = ({ strings, setPriceID, setIsCouponValid, setCouponStatusMessage, coupon, setCoupon }) => {
    const strContent = {
        couponLbl: strings.formContent.otherInputs.couponLabel,
        couponPh: strings.formContent.otherInputs.couponPlaceholder,
        coupCheckNotice: strings.formContent.coupons.checkNotice,
        coupValidNotice: strings.formContent.coupons.validNotice,
        coupInvalidNotice: strings.formContent.coupons.invalidNotice,
        coupExpiredNotice: strings.formContent.coupons.expiredNotice,
        coupConsumedNotice: strings.formContent.coupons.consumedNotice      
    }

    const resetCoupon = (e) => {
        e.preventDefault();
        e.target.value = "";
        setCouponStatusMessage("");
    }

    const checkCoupon = (coupon) => {
        if (coupon.length > 6) {
            // Inform the user that the coupon is being checked
            setCouponStatusMessage(strContent.coupCheckNotice);
            
            // Set parameters for URL fetch
            let _coupon = coupon.toUpperCase();
            let _action = '{"action": "check-coupon", "coupon": "' + _coupon + '"}';
            
            WebUtils.request(services.webServices, _action).then((_data) => {
                let _check_response = JSON.parse(_data);
                if (_check_response["valid"] === "y") {
                    // The coupon is valid
                    setPriceID(_check_response["price_id"]);
                    setIsCouponValid(true);
                    setCouponStatusMessage(strContent.coupValidNotice);
                } else {
                    // The coupon is not valid
                    setIsCouponValid(false);
                    if ((_check_response["valid"] === "n") && (_check_response["error"] === "invalid")) {
                        setCouponStatusMessage(strContent.coupInvalidNotice);
                    }
                    if ((_check_response["valid"] === "n") && (_check_response["error"] === "expired")) {
                        setCouponStatusMessage(strContent.coupExpiredNotice);
                    }
                    if ((_check_response["valid"] === "n") && (_check_response["error"] === "consumed")) {
                        setCouponStatusMessage(strContent.coupConsumedNotice);
                    }
                }
            })
        }
    }

    return (
        <Fragment>
            <input type = "text" className = "form-control" id = { ids.coupon.id } placeholder = { strContent.couponPh } value = { "none" === coupon ? "" : coupon } onChange = { (e) => { setCoupon(e.target.value); checkCoupon(e.target.value); }} onFocus = { (e) => resetCoupon(e) }></input>
            <label htmlFor = { ids.coupon.id } className = "form-label">{ strContent.couponLbl }</label>
        </Fragment>
    )
}

export default Coupon