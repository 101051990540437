import React, { useMemo, useState } from "react";
import styles from "./table.module.css";
import TableHeaderWrapper from "./table-header-wrapper";
import EntityTableRowWrapper from "./table-entity-row-wrapper";
import { DateUtils, MathUtils } from "../scripts/utils";
import urlData from "../scripts/urls";

/* Utility functions */
// Utility function to set entity table title and sub-title
const setEntityTableTitle = (name, ticker, CIK, industryDesc, strings) => {
    let _title;
    let _subTitle;

    _title = strings.tableTitles.entity.titleTemplate.replace("$1", name).replace("$2", ticker);
    _subTitle = strings.tableTitles.entity.subTitleTemplate.replace("$1", CIK).replace("$2", industryDesc);
    
    return [_title, _subTitle]
}

// Utility function to calculate total value
const calculateTotalValue = (price, amount) => {
    let _totalValue = null;
    let _price = parseFloat(price);
    
    // If there is a decimal value in amount, remove it
    if ("." in amount.split("")) {
        amount = amount.slice(0, amount.indexOf("."));
    }

    // Remove commas from amount
    amount = amount.replace(/,/g, "");

    amount = parseInt(amount);
    _totalValue = Math.round(_price * amount);
    return _totalValue;
}

// Utility function to calculate trade delta
const calculateDelta = (amount, amountOwned, amountPrefix) => {
    /**
     * The `calculateDelta` method calculates the delta for a trade. Delta is returned as a percentage
     * expressed as a rounded integer.
     * 
     * @param {string} amount The quantity of shares involved in the trade expressed as a string.
     * @param {string} amountOwned The quantity of shares beneficially owned by the purchaser or seller after the trade.
     * @param {string} amountPrefix Expressed as `+` or `-` and indicating if the trade is an acquisition or disposition.
     * @return {int} _delta
     * @catch {Error} err
     */
    try {
        let _delta = 0;

        let _intAmount = parseInt(amount.replace(/,/g, "").replace("$", ""));
        let _intAmountOwned = parseInt(amountOwned.replace(/,/g, "").replace("$", ""));

        // Set any NaN values to 0
        if (isNaN(_intAmount)) {
            _intAmount = 0;
        }
        if (isNaN(_intAmountOwned)) {
            _intAmountOwned = 0;
        }

        if ("+" === amountPrefix) {
            if (0 === _intAmountOwned - _intAmount) {
                _delta = 0; // In this case, the purchaser made an initial purchase of shares and had no prior position
            } else {
                _delta = (_intAmountOwned/(_intAmountOwned - _intAmount)) - 1;
            }
        }

        if ("-" === amountPrefix) {
            _delta = (_intAmountOwned/(_intAmountOwned + _intAmount)) - 1;
        }

        // DEBUG
        // console.log(`${amount}, ${_intAmount}, ${_delta}`);
        // console.log(`${amountOwned}, ${_intAmountOwned}, ${_delta}`);
        // DEBUG

        return Math.round(_delta * 100)
    } catch (err) {
        console.error(err);
    }
}

// Utility function to get selected transaction code buttons
const getSelectedTCBs = (tcbs) => {
    let _selected = [];
    for (let _tcb in tcbs) {
        if ("selected" === tcbs[_tcb]) {
            _selected.push(_tcb);
        }
    }
    return _selected
}

// Utility function to check if transaction is valid against selected transaction codes
const checkTransactionAgainstSelectedTCBs = (transactionCode, selectedTCBs) => {
    // Create a copy of selectedTCBs
    let _selectedTCBs = [...selectedTCBs]
    
    // Recursively search for supplied transaction code
    if (_selectedTCBs.length > 0) {
        if (_selectedTCBs[0] === transactionCode) {
            return true
        }
        return checkTransactionAgainstSelectedTCBs(transactionCode, _selectedTCBs.slice(1))
    }
    return false
}

// Utility function to check if transaction value is valid against supplied range
const checkTransactionValueAgainstRange = (transactionValue, min, max, cast, decimals) => {
    const ARTIFICIAL_MAX = 1000000000000;

    // Initialize flag for range validity
    let _validRangeFlag = null;

    // THIS LOGIC HAS BEEN COMMENTED OUT BECAUSE THE `advancedSearchSubmitClickHandler` IN THE
    // `AdvancedSearch` COMPONENT ALREADY PARSES NUMERICAL VALUES.
    // Cast min, max values to int or float depending on cast parameter
    // if ("float" === cast) {
    //     min = parseFloat(min);
    //     max = parseFloat(max);
    // } else {
    //     min = parseInt(min);
    //     max = parseInt(max);
    // }

    // Check if min or max are NaN; or if both are 0
    if (((isNaN(min)) || (isNaN(max))) || ((0 === min) && (0 === max))) {
        _validRangeFlag = false;
    }

    // Check if max is not supplied, i.e. min is valid number and max is 0.
    // If only a min value is supplied, set max to artifically large number.
    if ((typeof(min) === "number") && (0 === max)) {
        max = ARTIFICIAL_MAX;
    }

    // Check if we have a valid range
    if (min < max) {
        // Min is less than or equal to max
        _validRangeFlag = true;
    } else {
        _validRangeFlag = false;
    }

    // Return based on _validRangeFlag and supplied price
    if (_validRangeFlag) {
        // Convert the price to a number
        let _num = MathUtils.stringConvertToNumber(transactionValue, cast, "$", ",", decimals);

        // Check if _num is within range.
        if ((_num >= min) && (_num <= max)) {
            return true // The price is within the desired range
        }
        return false // The price is outside the desired range
    }
    return true // If the range was invalid, assume all transaction prices should be displayed
}

// Utility function to check if transaction date is valid against specified range
const checkTransactionDateAgainstRange = (transactionDate, range) => {
    // Initialize range in days variable
    let rangeInDays = 0;
    
    // Calculate the range in days using the supplied range value; return true if range is for all dates
    if (("all dates" === range) || (null === range)) {
        return true;
    } else if ("latest day" === range) {
        rangeInDays = 1;
    } else if ("latest 3 days" === range) {
        rangeInDays = 3;
    } else if ("last 1 week" === range) {
        rangeInDays = 7;
    } else if ("last 1 year" === range) {
        rangeInDays = 365;
    } else if ("last 2 years" === range) {
        rangeInDays = 720;
    } else if ("last 3 years" === range) {
        rangeInDays = 1095;
    } else if ("last 4 years" === range) {
        rangeInDays = 1460;
    } else if ("last 5 years" === range) {
        rangeInDays = 1825;
    }

    // Calculate the earliest allowable date using range in days value
    let earliestDate = new Date(DateUtils.subtractDaysFromDateObject(new Date(), rangeInDays));

    // Convert the transaction date to date object
    let date = new Date(transactionDate);

    // DEBUG
    // console.group();
    //     console.log(earliestDate);
    //     console.log(date);
    // console.groupEnd();
    // DEBUG

    // Return true if transactionDate is later than earliestDate; false otherwise
    if (date >= earliestDate) {
        return true
    } else {
        return false
    }
}

// Utility function to check if transaction owner title is valid against selected title
const checkTransactionAgainstTitle = (transactionTitle, selectedTitle, strings, stringsKey) => {
    // If selected title is "all", return true
    if (("all" === selectedTitle) || (null == selectedTitle)) {
        return true;
    }

    // Initialize _validTitleFlag to false
    let _validTitleFlag = false;

    // Get the array of title variations for the selected title
    let titleVariations = strings[stringsKey][selectedTitle]["strings"];

    // Change transaction title to lower case
    let lowerCaseTitle = transactionTitle.toLowerCase();

    // Check if the transaction title matches anything in the title variations list
    titleVariations.forEach((titleVariation) => {
        if (lowerCaseTitle.indexOf(titleVariation) > -1) {
            _validTitleFlag = true;
        }
    });
    
    // Return true/false based on _validTitleFlag
    if (_validTitleFlag) {
        return true
    } else {
        return false
    }
}

// Utility function to check transaction against input text
const checkTransactionAgainstInput = (transactionText, inputText) => {
    // If transaction text is null or "", return true
    if ((null === inputText) || ("" === inputText)) {
        return true
    }

    // Convert transaction text and input text to lower case
    let lowerCaseTransactionText = transactionText.toLowerCase();
    let lowerCaseInputText = inputText.toLowerCase();

    // Check if the input text matches the supplied transaction text
    if (lowerCaseTransactionText.indexOf(lowerCaseInputText) > -1) {
        return true
    }
    return false
}

// Utility function to check transaction against advanced search parameters
const checkTransactionAgainstSearchParameters = (transaction, parameters, strings) => {
    // Initialize flags
    let _validPrice = false;
    let _validTradeValue = false;
    let _validFilingDate = false;
    let _validSecurityTitle = false;
    let _validOwnerTitle = false;
    let _validOwnerName = false;
    let _validTransactionType = false;

    // Get search parameters
    let _selectedSharePriceMin = parameters["sharePriceMin"];
    let _selectedSharePriceMax = parameters["sharePriceMax"]
    let _selectedLiquidityMin = parameters["liquidityMin"];
    let _selectedLiquidityMax = parameters["liquidityMax"];
    let _selectedFilingDateRange = parameters["filingDate"];
    let _selectedSecurityTitle = parameters["security"];
    let _selectedOwnerTitle = parameters["owner"];
    let _selectedOwnerName = parameters["ownerName"];
    let _selectedTCBs = getSelectedTCBs(parameters["selectedTCB"]);

    // Get transaction data
    let _filingDate = transaction["filingDate"];
    let _securityTitle = transaction["securityTitle"];
    let _reportingPerson = transaction["reportingPerson"];
    let _reportingPersonTitle = transaction["reportingPersonTitle"];
    let _price = transaction["price"];
    let _tradeValue = transaction["totalValue"];
    let _transactionCode = transaction["transactionCode"].toLowerCase();

    // Share price
    _validPrice = checkTransactionValueAgainstRange(_price, _selectedSharePriceMin, _selectedSharePriceMax, "float", 2);

    // Trade value
    _validTradeValue = checkTransactionValueAgainstRange(_tradeValue, _selectedLiquidityMin, _selectedLiquidityMax, "int", 0);

    // Filing date
    _validFilingDate = checkTransactionDateAgainstRange(_filingDate, _selectedFilingDateRange);

    // Security title
    _validSecurityTitle = checkTransactionAgainstTitle(_securityTitle, _selectedSecurityTitle, strings, "securityOptions");

    // Owner title
    _validOwnerTitle = checkTransactionAgainstTitle(_reportingPersonTitle, _selectedOwnerTitle, strings, "ownerOptions");

    // Owner name
    _validOwnerName = checkTransactionAgainstInput(_reportingPerson, _selectedOwnerName);

    // Transaction codes
    if (0 === _selectedTCBs.length) {
        // In this case, assume all transactions are valid
        _validTransactionType = true;
    } else {
        // In this case, check if the transaction is valid
        _validTransactionType = checkTransactionAgainstSelectedTCBs(_transactionCode, _selectedTCBs);
    }

    if ((_validPrice) && (_validTradeValue) && (_validFilingDate) && (_validSecurityTitle) && (_validOwnerTitle) && (_validOwnerName) && (_validTransactionType)) {
        return true
    } else {
        return false
    }
}

// Utility function to add rows to table data array
const addTableDataRows = (rows, rawData, accessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings) => {
    if (accessionNumKeys.length > 0) {
        // Get the first accession number in the remaining accession numbers list
        let _key = accessionNumKeys[0];

        // Get filing metadata for this accession number
        let _filingMetaData = rawData["metadata"]["4"][_key];

        // Get Form 4 data for this accession number
        let _filingForm4Data = rawData["form_4"]["4"][_key];

        // Get the table 1 and table 2 keys for this Form 4 filing
        let _table1Keys = Object.keys(_filingForm4Data["table1"]);
        let _table2Keys = Object.keys(_filingForm4Data["table2"]);

        // Generate table 1 and table 2 rows
        let _table1Rows = [];
        let _table2Rows = [];
        if (_table1Keys.length > 0) {
            _table1Rows = addTable1Rows([], _filingMetaData, _filingForm4Data, _key, _table1Keys, urlData, searchParameters, isAdvancedSearchVisible, strings);
        }
        
        if (_table2Keys.length > 0) {
            _table2Rows = addTable2Rows([], _filingMetaData, _filingForm4Data, _key, _table2Keys, urlData, searchParameters, isAdvancedSearchVisible, strings);
        }

        // Create a combined array of table 1 and table 2 rows
        let _combinedTableRows = [..._table1Rows, ..._table2Rows];

        // Add _combinedTableRows to rows
        rows = _combinedTableRows.length > 0 ? rows.concat(_combinedTableRows) : rows;

        // Slice the accessionNumKeys array
        let remainingAccessionNumKeys = accessionNumKeys.slice(1);

        return addTableDataRows(rows, rawData, remainingAccessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings)
    } else {
        return rows
    }
};

// Utility function to build table 1 transaction rows for table data array
const addTable1Rows = (table1, filingMetaData, filingForm4Data, accessionKey, table1Keys, urlData, searchParameters, isAdvancedSearchVisible, strings) => {
    if (table1Keys.length > 0) {
        // Initialize _pushTransaction
        let _pushTransaction = true;
        
        // Get the first filing number in the remaining filing numbers list
        let _table1Key = table1Keys[0];

        // Add the transaction with _table1Key to table1
        // 1. First grab fields that don't require calcs
        let _transactionFilingDate = filingMetaData["date"];
        let _earliestTransactionDate = filingForm4Data["box3"]["earliest_transaction_date"];
        let _issuerTicker = filingForm4Data["box2"]["issuer_ticker"];
        let _reportingPerson = filingForm4Data["box1"]["reporting_person"];
        let _reportingPersonLink = urlData.edgar_prefix + filingForm4Data["box1"]["reporting_person_link"];
        let _reportingPersonTitle = filingForm4Data["box5"]["relationship_title"];
        let _individualFiling = filingForm4Data["box6"]["individual_filing"];
        let _securityTitle = filingForm4Data["table1"][_table1Key]["non_deriv_security_title"];
        let _acqOrDis = filingForm4Data["table1"][_table1Key]["non_deriv_acq_or_dis"];
        let _transactionCode = filingForm4Data["table1"][_table1Key]["non_deriv_transaction_code"];
        let _price = filingForm4Data["table1"][_table1Key]["non_deriv_price"];
        let _amountPrefix = _acqOrDis === "A" ? "+" : "-";
        let _amount = filingForm4Data["table1"][_table1Key]["non_deriv_amount"]; 
        let _amountOwned = filingForm4Data["table1"][_table1Key]["non_deriv_amount_beneficially_owned"];
        let _amountOwnedNum = parseInt(_amountOwned.replace(/,/g, "")); // Use this value for sorting Table 1 by amount owned if multiple transactions
        // let _issuerName = filingForm4Data["box_2"]["issuer_name"];
        // let _issuerLink = urlData.edgar_prefix + filingForm4Data["box_2"]["issuer_link"];
        // let _SICCode = tableData["basic"]["sic_desc"];

        // 2. Perform calcs to generate total value and delta fields
        let _totalValue = calculateTotalValue(_price, _amount);
        let _delta = calculateDelta(_amount, _amountOwned, _amountPrefix);

        // 3. Convert individual filing to appropriate code
        let _individualFilingCode = _individualFiling === "X" ? "I" : "J";

        // 4. Format selected fields
        _transactionFilingDate = DateUtils.convertDate(_transactionFilingDate, "DATE_SLASH");
        _earliestTransactionDate = DateUtils.convertDate(_earliestTransactionDate, "DATE_SLASH");
        _price = MathUtils.numberConvertToString(_price, ["ADD_SYMBOL", "DECIMALS"], "$", "", 2);
        _amountOwned = MathUtils.numberConvertToString(parseInt(_amountOwned.replace(/,/g, "")), ["SEPARATOR"], "", ",", 0);
        _delta = MathUtils.numberConvertToString(_delta, ["PERCENT"], "", "", 0);
        _totalValue = MathUtils.numberConvertToString(_totalValue, ["ADD_SYMBOL", "SEPARATOR", "TBMK"], "$", ",", null, 1000000);

        // 5. Add prefixes, N/A checks, 0% checks
        let _prefixedAmount = _amount !== "N/A" ? _amountPrefix + _amount : _amount;
        // let _prefixedDelta = _delta !== "0%" ? _amountPrefix + _delta : _delta;
        let _prefixedDelta = _delta

        // Add all fields to an object for this table 1 transaction
        let _transaction = {
            "accessionNum": accessionKey,
            "transactionType": "non-deriv",
            "filingDate": _transactionFilingDate,
            "earliestTransactionDate": _earliestTransactionDate,
            "issuerTicker": _issuerTicker,
            "reportingPerson": _reportingPerson,
            "reportingPersonLink": _reportingPersonLink,
            "reportingPersonTitle": _reportingPersonTitle,
            "individualFilingCode": _individualFilingCode,
            "securityTitle": _securityTitle,
            "transactionCode": _transactionCode,
            "price": _price,
            "amount": _prefixedAmount,
            "totalValue": _totalValue,
            "amountOwned": _amountOwned,
            "amountOwnedNum": _amountOwnedNum,
            "delta": _prefixedDelta,
            "acqOrDis": _acqOrDis
        };

        // Check the transaction if using advanced search parameters
        if (1 === isAdvancedSearchVisible) {
            _pushTransaction = checkTransactionAgainstSearchParameters(_transaction, searchParameters, strings);
        }

        // Add the transaction
        if (_pushTransaction) {
            table1.push(_transaction);
        }

        // Sort the table by `amountOwned`
        table1.sort((a, b) => b["amountOwnedNum"] - a["amountOwnedNum"]);

        // Slice the table1Keys list
        let remainingTable1Keys = table1Keys.slice(1);

        // Recursively call addTable1Rows
        return addTable1Rows(table1, filingMetaData, filingForm4Data, accessionKey, remainingTable1Keys, urlData, searchParameters, isAdvancedSearchVisible, strings)
    } else {
        return table1;
    }
}

// Utility function to build table 2 transaction rows for table data array
const addTable2Rows = (table2, filingMetaData, filingForm4Data, accessionKey, table2Keys, urlData, searchParameters, isAdvancedSearchVisible, strings) => {
    if (table2Keys.length > 0) {
        // Initialize _pushTransaction
        let _pushTransaction = true;

        // Get the first filing number in the remaining filing numbers list
        let _table2Key = table2Keys[0];

        // Add the transaction with _table2Key to table2
        // 1. First grab fields that don't require calcs
        let _transactionFilingDate = filingMetaData["date"]; 
        let _earliestTransactionDate = filingForm4Data["box3"]["earliest_transaction_date"];
        let _issuerTicker = filingForm4Data["box2"]["issuer_ticker"];
        let _reportingPerson = filingForm4Data["box1"]["reporting_person"];
        let _reportingPersonLink = urlData.edgar_prefix + filingForm4Data["box1"]["reporting_person_link"];
        let _reportingPersonTitle = filingForm4Data["box5"]["relationship_title"];
        let _individualFiling = filingForm4Data["box6"]["individual_filing"];
        let _securityTitle = filingForm4Data["table2"][_table2Key]["deriv_security_title"];
        let _transactionCode = filingForm4Data["table2"][_table2Key]["deriv_transaction_code"];
        let _derivAmountAcq = filingForm4Data["table2"][_table2Key]["deriv_amount_acq"];
        let _price = filingForm4Data["table2"][_table2Key]["deriv_security_price_value"];
        let _amountPrefix = _derivAmountAcq !== "N/A" ? "+" : "-"; 
        let _amount = filingForm4Data["table2"][_table2Key]["deriv_underlying_security_amount"]
        let _amountOwned = filingForm4Data["table2"][_table2Key]["deriv_amount_beneficially_owned"];
        let _amountOwnedNum = parseInt(_amountOwned.replace(/,/g, "")); // Use this value for sorting Table 2 by amount owned if multiple transactions
        // let _issuerName = tableData["form_4"][key]["box_2"]["issuer_name"];
        // let _issuerLink = urlData.edgar_prefix + tableData["form_4"][key]["box_2"]["issuer_link"];
        // let _SICCode = tableData["basic"]["sic_desc"];
        // let _derivAmountDis = tableData["form_4"][key][tableKey][tableRow]["deriv_amount_dis"];

        // 2. Perform calcs to generate total value and delta fields
        let _totalValue = calculateTotalValue(_price, _amount);
        let _delta = calculateDelta(_amount, _amountOwned, _amountPrefix);

        // 3. Convert individual filing to appropriate code
        let _individualFilingCode = _individualFiling === "X" ? "I" : "J";

        // 4. Format selected fields
        _transactionFilingDate = DateUtils.convertDate(_transactionFilingDate, "DATE_SLASH");
        _earliestTransactionDate = DateUtils.convertDate(_earliestTransactionDate, "DATE_SLASH");
        _price = MathUtils.numberConvertToString(_price, ["ADD_SYMBOL", "DECIMALS"], "$", "", 2);
        _amount = MathUtils.numberConvertToString(parseInt(_amount.replace(/,/g, "")), ["SEPARATOR"], "", ",", 0); // `parseInt` will automatically cut off fractional shares (i.e. anything after a decimal)
        _amountOwned = MathUtils.numberConvertToString(parseInt(_amountOwned.replace(/,/g, "")), ["SEPARATOR"], "", ",", 0); // `parseInt` will automatically cut off fractional shares (i.e. anything after a decimal)
        _delta = MathUtils.numberConvertToString(_delta, ["PERCENT"], "", "", 0);
        _totalValue = MathUtils.numberConvertToString(_totalValue, ["ADD_SYMBOL", "SEPARATOR", "TBMK"], "$", ",", null, 1000000);
    
        // 5. Add prefixes and N/A checks
        let _prefixedAmount = _amount !== "N/A" ? _amountPrefix + _amount : _amount;
        // let _prefixedDelta = _delta !== "0%" ? _amountPrefix + _delta : _delta;
        let _prefixedDelta = _delta;

        // Add all fields to an object for this table 2 transaction
        let _transaction = {
            "accessionNum": accessionKey,
            "transactionType": "deriv",
            "filingDate": _transactionFilingDate,
            "earliestTransactionDate": _earliestTransactionDate,
            "issuerTicker": _issuerTicker,
            "reportingPerson": _reportingPerson,
            "reportingPersonLink": _reportingPersonLink,
            "reportingPersonTitle": _reportingPersonTitle,
            "individualFilingCode": _individualFilingCode,
            "securityTitle": _securityTitle,
            "transactionCode": _transactionCode,
            "price": _price,
            "amount": _prefixedAmount,
            "totalValue": _totalValue,
            "amountOwned": _amountOwned,
            "amountOwnedNum": _amountOwnedNum,
            "delta": _prefixedDelta,
            "acqOrDis": _transactionCode // Proxy for acqOrDis
        };

        // Check the transaction if using advanced search parameters
        if (1 === isAdvancedSearchVisible) {
            _pushTransaction = checkTransactionAgainstSearchParameters(_transaction, searchParameters, strings);
        }

        // Add the transaction
        if (_pushTransaction) {
            table2.push(_transaction);
        };

        // Sort the table by `amountOwned`
        table2.sort((a, b) => b["amountOwnedNum"] - a["amountOwnedNum"]);

        // Slice the table1Keys list
        let remainingTable2Keys = table2Keys.slice(1);

        // Recursively call addTable1Rows
        return addTable2Rows(table2, filingMetaData, filingForm4Data, accessionKey, remainingTable2Keys, urlData, searchParameters, isAdvancedSearchVisible, strings)
    } else {
        return table2
    }    
}

// Utility function to build table
const buildTableData = (data, urlData, searchParameters, isAdvancedSearchVisible, strings) => {    
    // Set accession numbers
    let _accessionNumKeys = Object.keys(data["form_4"]["4"]);

    // Call the addTableDataRows function to add data rows to tableData
    let _tableData = addTableDataRows([], data, _accessionNumKeys, urlData, searchParameters, isAdvancedSearchVisible, strings);

    // Return table data
    return _tableData
};

const EntityTable = ({ CIK, data, isAdvancedSearchVisible, searchParameters, strings, isMobile }) => {
    // Table data hook
    let _tableData = buildTableData(data, urlData, searchParameters, isAdvancedSearchVisible, strings);

    // Custom useSortedData hook
    const useSortedData = (data) => {
        const [sortConfig, setSortConfig] = useState({ "field": "filingDate", "direction": "descending" });        
        
        const sortedData = useMemo(() => {
            const dateSortFields = ["filingDate", "earliestTransactionDate"];
            const textSortFields = ["reportingPerson", "reportingPersonTitle", "issuerTicker", "individualFilingCode", "securityTitle", "transactionCode"];
            const numSortFields = ["price", "amount", "totalValue", "amountOwned", "delta"];            
            let _dataAfterSort = [...data];
            if (dateSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let _date1 = new Date(a[sortConfig["field"]]);
                    let _date2 = new Date(b[sortConfig["field"]]);
        
                    if (sortConfig["direction"] === "ascending") {
                        return _date1 - _date2
                    } else {
                        return _date2 - _date1
                    }                
                });
            } else if (textSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let _text1 = a[sortConfig["field"]];
                    let _text2 = b[sortConfig["field"]];
                    
                    if (_text1.localeCompare(_text2) === -1) {
                        return sortConfig["direction"] === "ascending" ? -1  : 1
                    } else if (_text1.localeCompare(_text2) === 1) {
                        return sortConfig["direction"] === "ascending" ? 1 : -1
                    } else {
                        return 0
                    }
                });
            } else if (numSortFields.indexOf(sortConfig["field"]) > -1) {
                _dataAfterSort.sort((a,b) => {
                    let numString1 = a[sortConfig["field"]];
                    let numString2 = b[sortConfig["field"]];
                    
                    // Remove special characters, except period
                    numString1 = numString1.replace(/[$,%\-+]/g, "");
                    numString2 = numString2.replace(/[$,%\-+]/g, "");

                    // Check if this is an integer or floating-point value
                    let numType = sortConfig["field"] === "price" ? "float" : "int";

                    // Cast numString1 and numString2 to int or float
                    let num1, num2;
                    if("float" === numType) {
                        num1 = numString1 !== "N/A" ? parseFloat(numString1) : 0;
                        num2 = numString2 !== "N/A" ? parseFloat(numString2) : 0;
                    } else {
                        num1 = numString1 !== "N/A" ? parseInt(numString1) : 0;
                        num2 = numString2 !== "N/A" ? parseInt(numString2) : 0;
                    }

                    if (sortConfig["direction"] === "ascending") {
                        return num1 - num2;
                    } else {
                        return num2 - num1;
                    }
                });
            } else {
                _dataAfterSort.sort();
            }
            return _dataAfterSort
        }, [data, sortConfig]);

        const setTableDataSort = (e, field) => {
            let _direction;
            if (field !== sortConfig["field"]) {
                _direction = "descending";
            } else {
                if (sortConfig["direction"] === "descending") {
                    _direction = "ascending";
                    // console.log("setting field to ", field, " and direction to ", _direction);
                } else {
                    _direction = "descending";
                    // console.log("setting field to ", field, " and direction to ", _direction);
                }
            }
            setSortConfig ({ "field": field, "direction": _direction });
        }

        return { tableData: sortedData, setTableDataSort, sortConfig: sortConfig }
    }

    const { tableData, setTableDataSort, sortConfig } = useSortedData(_tableData);

    // Set the entity table title.    
    let [_tableTitle, _tableSubTitle] = setEntityTableTitle(data["basic"]["name"], data["basic"]["ticker"], data["basic"]["cik_code"], data["basic"]["sic_desc"], strings);

    return(
        <React.Fragment>
            <h2 className = { ["mt-3", styles.tableTitle].join(" ") }>
                { `${_tableTitle}` }
            </h2>
            <h6 className = { styles.tableSubTitle }>
                { `${_tableSubTitle}` }
            </h6>
            <div className={ styles.tableContainer }>
                <table>
                    <thead>
                        <tr className={ styles.tableHeader }>
                            {
                                Object.keys(strings.entityTableHeaders).map((headerKey, index) => <TableHeaderWrapper key = { `${CIK}-${headerKey}` } headerKey = { headerKey } index = { index } headers = { strings.entityTableHeaders } handlers ={ {click: setTableDataSort} } sortConfig = { sortConfig } isMobile = { isMobile }></TableHeaderWrapper>)
                            }
                        </tr>
                    </thead>                
                    <tbody>
                        {
                            tableData.map((rowData, rowIndex) => <EntityTableRowWrapper key = { `${rowData["accessionNum"]}-${rowIndex}` } rowData = { rowData } rowIndex = { rowIndex } isMobile = { isMobile } strings = { strings }></EntityTableRowWrapper>)
                        }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default EntityTable
