import Entities from "./entities";
import Reports from "./reports";
import Articles from "./articles";

const ids = {
  "area": { "id": "docArea" }
}

const Doc = ({ SEARCH_INPUT_REGEX, isAdvancedSearchVisible, searchParameters, allIndustries, strings, isMobile, youngestReportPeriod, selectedSubNavOption, articles }) => {
  switch (selectedSubNavOption) {
    case (0):
      if ("entity" === searchParameters["intent"]) {
        return(
          <div id = { ids.area.id } className = "mt-3">
            <Entities
              isAdvancedSearchVisible = { isAdvancedSearchVisible }
              searchParameters = { searchParameters }
              strings = { strings }
              isMobile = { isMobile }>
            </Entities>
          </div>
        )
      };
      if ("report" === searchParameters["intent"]) {
        return(
          <div id = { ids.area.id } className = "mt-3">
            <Reports
                SEARCH_INPUT_REGEX = { SEARCH_INPUT_REGEX }
                isAdvancedSearchVisible = { isAdvancedSearchVisible }
                searchParameters = { searchParameters }
                allIndustries = { allIndustries }
                strings = { strings }
                isMobile = { isMobile }
                youngestReportPeriod = { youngestReportPeriod }>
            </Reports>
          </div>
        )
      };
      break;
    case(1):
      return(
        <Articles articles = { articles } entity = { searchParameters["entity"] } strings = { strings }></Articles>
      )
    default:
      // pass
  }
}

export default Doc